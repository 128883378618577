import React, { useEffect, useState } from "react";
import { Switch, useHistory } from "react-router-dom";
import { getApiKey } from "./helpers";
import PrivateRoute from "./utils/RestrictedRoute/PrivateRoutes";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import NotesPage from "./pages/NotesPage";
import PublicRoute from "./utils/RestrictedRoute/PublicRoutes";
import SignUpMain from "./pages/SignUp/SignUpMain";
import SignUpStepOne from "./pages/SignUp/SignUpStepOne";
import SignUpStepTwo from "./pages/SignUp/SignUpStepTwo";
import SignUpStepThree from "./pages/SignUp/SignUpStepThree";
import LoginPage from "./pages/LoginPage";
import ForgetPassword from "./pages/ForgetPassword";
import ConfirmPassword from "./pages/ConfirmPassword";
import MainPage from "./pages/MainPage";

import SubscriptionPage from "./pages/SubscriptionPage";
import MainAdminPage from "./pages/MainAdminPage";
import Header from "./components/AdminHeader";
import Headers from "./components/AuthHeader";
import AllSubscription from "./pages/MySubscription/AllSubscriptions";
import Footer from "./components/Footer";
import TermsConditions from "./pages/Terms&Conditions/terms-conditions";
import StudyTips from "./pages/StudyTips/study-tips";
import YourLibraryPage from "./pages/YourLibraryPage";
import TutoringServices from "./pages/TutoringServices";
import BecomeCreatorStepOne from "./pages/BecomeContentCreator/StepOne";
import BecomeCreatorStepTwo from "./pages/BecomeContentCreator/StepTwo";
import BecomeCreatorStepThree from "./pages/BecomeContentCreator/StepThree";
import PlanYourStudyModule from "./pages/PlanYourStudyModule/PlanYourStudyModule";
import ContentCreatorPortal from "./pages/ContentCreatorDashboard/ContentCreatorPortal";
import CookiesPolicy from "./pages/CookiesPolicy/cookies_policy";
import PrivacyPolicy from "./pages/PrivacyPolicy/privacy_policy";
import ContentWelcome from "./pages/ContentCreatorWelcome/content-welcome";
import OurPartners from "./pages/OurPartners/our-partners";
import StudyModulePage from "./pages/StudyModulePage";
import FlashCards from "./pages/FlashCards";
import OneToOne from "./pages/BookTutorials/OneToOne";
import OneToOnePayment from "./pages/BookTutorials/OneToOnePayment";
import ContactUs from "./pages/ContactUs/contact_us";
import AboutUs from "./pages/AboutUs/about_us";
import BookTutorialsGroup from "./pages/BookTutorials/BookTutorialsGroup";
import TutorSelect from "./pages/TutoringServices/tutorSelect";
import PreviewNotes from "./pages/PreviewNotes";
import PreviewEssay from "./pages/PreviewEssay";
import ModelEssays from "./pages/ModelEssays";
import TutorDashboard from "./pages/TutorDashboard/tutorDashboard";
import TimeSlots from "./pages/TutorDashboard/timeSlots";
import Sessions from "./pages/TutorDashboard/sessions";
import SessionDetail from "./pages/TutorDashboard/sessionDetail";
import CancelOrder from "./pages/BookTutorials/CancelOrder";
import SuccessOrder from "./pages/BookTutorials/SuccessOrder";
import CheckoutPage from "./pages/CheckoutPage";
import ShoppingBasket from "./pages/ShoppingBasket";
import UserSessions from "./pages/UserSessions/user-sessions";
import ContentCreatorDashboard from "./pages/ContentCreatorDashboard/ContentCreatorDashboard";
import MoreContent from "./pages/BecomeContentCreator/MoreContent";
import BecomeContentCreator from "./pages/BecomeContentCreator";
import StripeCheckoutPage from "./components/StripeCheckout/stripeCheckout";
import CaseSummaries from "./pages/CaseSummaries";
import { type } from "./pages/IndividualCaseSummaries";
import TutorialVideoFreeUsers from "./pages/TutorialVideos/TutorialVideoFreeUsers";
import QuizList from "./pages/Quiz_List";
import PaidUsersQuiz from "./pages/Quiz/PaidUsersQuiz";
import QuizResults from "./pages/QuizResults";
import GrabDetail from "./pages/GrabDetail/index.js";
import Review from "./pages/Review&Feed/index.js";
import SubscriptionCancellation from "./pages/SubscriptionCancellation";
import SubscriptionCancellationStepTwo from "./pages/SubscriptionCancellation/StepTwoSubscription";
import SubscriptionCancellationStepThree from "./pages/SubscriptionCancellation/StepThreeSubscription";
import { useDispatch, useSelector } from "react-redux";
import { createErrorAlert } from "./redux/actions";
import termsConditionsContent from "./pages/Terms&Conditions/terms-conditions-content";
import SideNavComponent from "./components/SideNav/sideNav";
import ContentReceipt from "./pages/ContentReceipts/ContentReceipts";
import PaymentMethod from "./pages/PaymentMethod";
import CallToAction from "./components/CallToAction/index.js";
import TutoringSelection from "./pages/TutoringServices/TutoringSelection.js";
import StepZero from "./pages/BecomeContentCreator/StepZero.js";
import Blogs from "./pages/Blogs/Blogs.js";
import BlogsDetail from "./pages/Blogs/BlogsDetail.js";
import SignUpStepZero from "./pages/SignUp/SignUpStepZero.js";
import ContentCreatorShared from "./pages/ContentCreatorDashboard/ContentCreatorShared.js";
import AITutor from "./pages/AITutor/AiTutor.js";
import AISideNav from "./components/SideNav/AISideNav.js";

function App() {
  const key = getApiKey();
  const dispatch = useDispatch();
  const history = useHistory()
  const [footer, setFooter] = useState(false);
  const [locate, setLocate] = useState(window.location.pathname);
  const [marginState, setMarginState] = useState(false);
  const [callToAction, setCallToAction] = useState(false)
  const [paidModules, setPaidModules] = useState([]);
  const [moduleLaw, setModuleLaw] = useState(localStorage.getItem('studyModule'));

  const [routed, setRouted] = useState(false);

  const paidModulesState = useSelector((state) => state.store.catalogueReducer?.data?.data)

  const user = JSON.parse(sessionStorage.getItem("app_user"));
  let url;

  const handleGetCatalogue = () => {
    if (paidModulesState?.length > 0) {
        let arr = []
        for (let i = 0; i < paidModulesState?.length; i++) {
            if (paidModulesState[i]?.is_paid === true) {
                if (!arr.includes(paidModulesState[i]?.study_module?.slug)) {
                    arr.push(paidModulesState[i]?.study_module?.slug);
                }
            }
        }
        setPaidModules(arr)
    }
}

useEffect(() => {
    handleGetCatalogue()
}, [paidModulesState, window.location.pathname])


// useEffect(() => {
//   if (getApiKey() && !routed) {
//     if(user?.login_from === "signup"){
//       if (
//         (user?.degrees.length === 0 && user?.universities.length === 0)
//       ) {
//         history.push('/signup/step-two');
//         setRouted(true); // Update state to prevent re-routing
//       } else if (user?.degrees.length === 0 && user?.universities.length !== 0) {
//         history.push('/signup/step-three');
//         setRouted(true); // Update state to prevent re-routing
//       } else if(user?.degrees.length === 0 && user?.universities.length === 0){
//         history.push('/signup/step-zero');
//         setRouted(true)
//       } else if(user?.degrees !== undefined && user?.universities !== undefined && user?.user_type !== "TEACHER" ){
//         history.push('/profile');
//         setRouted(true)
//       }
//     } else if(user?.login_from === "content"){
//       if (
//         (user?.degrees.length === 0 && user?.universities.length === 0)
//       ) {
//         history.push('/content-creator/step-zero');
//         setRouted(true); // Update state to prevent re-routing
//       } else if (user?.degrees.length === 0 && user?.universities.length !== 0) {
//         history.push('/content-creator/step-one');
//         setRouted(true); // Update state to prevent re-routing
//       } else if(user?.degrees.length === 0 && user?.universities.length === 0){
//         history.push('/content-creator/step-zero');
//         setRouted(true)
//       } else if(user?.degrees !== undefined && user?.universities !== undefined){
//         // history.push('/profile');
//         setRouted(true)
//       }
//     }
//   }
// }, [user, routed]);

  if (
    window.location.pathname !== "/book-tutorials/appointment/one-2-one" ||
    window.location.pathname !== "/book-tutorials/one-to-one-payment"
  ) {
    localStorage.removeItem("dates");
    localStorage.removeItem("tutor");
  }

  if (window.location.pathname.includes("stripe_account_id")) {
    dispatch(
      createErrorAlert(
        201,
        "",
        "Kindly check you Email for Connect account onboarding",
        201
      )
    );
  }

  if (window.location.href.includes("membership")) {
    setTimeout(() => {
      dispatch(
        createErrorAlert(
          209,
          "Success",
          `<p><span className="text-center">🎉 <b>Congratulations!</b> 🎉</span> <br/> <p className="text-left">You have successfully upgraded your membership to the <b>${user?.subscription_name}</b> for improving your grades. 📚✨.<br/> Enjoy unlimited access to our condensed notes and case summaries! <br/><br/> Please note that if you want unlimited access to our simple study materials to avoid reading lengthy and overcomplicated textbooks, while maximizing your chances of getting a First Class, you will need to upgrade to the Premium Pack for just £10/month. 🚀📖<br/></br>Happy studying! 📘🌟</p></p>`,
          209
        )
      );
    }, 2000);
  } else if (window.location.href.includes("appointments_create")) {
    let msg =
      "Congratulations! You have successfully booked the Tutoring Session.";
    setTimeout(() => {
      dispatch(createErrorAlert(700, "Success", msg, 700));
    }, 2000);
  } else if (window.location.href.includes("case_study")) {
    let msg =
    `<p><span className="text-center">🎉 <b>Congratulations!</b> 🎉</span> <br/> <p className="text-left">You have successfully upgraded your membership to the <b>${user?.subscription_name}</b> for improving your grades. 📚✨.<br/> Enjoy unlimited access to our condensed notes and case summaries! <br/><br/> Please note that if you want unlimited access to our simple study materials to avoid reading lengthy and overcomplicated textbooks, while maximizing your chances of getting a First Class, you will need to upgrade to the Premium Pack for just £10/month. 🚀📖<br/></br>Happy studying! 📘🌟</p></p>`
    setTimeout(() => {
      dispatch(createErrorAlert(201, "Success", msg, 201));
    }, 2000);
  }

  navigator.storage.estimate().then(({ quota, usage }) => {
    console.log(`Used: ${(usage / 1024 / 1024).toFixed(2)} MB`);
    console.log(`Total: ${(quota / 1024 / 1024).toFixed(2)} MB`);
  });

  useEffect(() => {
    url = localStorage.getItem("urlSlug");
    setInterval(() => {
      if (
        window.location.pathname.includes("/notes/") ||
        window.location.pathname.includes("one-to-one-payment") ||
        window.location.pathname.includes("/subscription") ||
        window.location.pathname.includes("/model-essays/") ||
        window.location.pathname.includes("flashcards") ||
        window.location.pathname.includes("tutorial-videos") ||
        window.location.pathname.includes("/quiz/") ||
        window.location.pathname.includes("quizzes") ||
        window.location.pathname.includes("signup") ||
        window.location.pathname.includes("login") ||
        window.location.pathname.includes(`/${url}/`)
      ) {
        let help = document.getElementsByClassName("helpcrunch-iframe-wrapper");
        help[0].style.display = "none";
      }
      if (window.location.pathname === "/") {
        let help = document.getElementsByClassName("helpcrunch-iframe-wrapper");
        help[0].style.display = "block";
      }
      if (
        window.location.href.includes(
          "http://localhost:3000/?redirect_page=appointments_create"
        )
      ) {
        let help = document.getElementsByClassName("helpcrunch-iframe-wrapper");
        help[0].style.display = "none";
      }
      if (
        window.location.pathname === "/signup" ||
        window.location.pathname === "/login"
      ) {
        setFooter(false);
      } else {
        setFooter(true);
        let help = document.getElementsByClassName("helpcrunch-iframe-wrapper");
        help[0].style.display = "block";
      }
      setLocate(window.location.pathname);
    }, 2000);
  }, [window.location, locate]);

  useEffect(() => {
    const handleStorageChange = () => {
      setModuleLaw(localStorage.getItem('studyModule'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    if (!isLocalhost && window.location.protocol === 'http:') {
      window.location.href = window.location.href.replace('http://', 'https://');
    }
  }, []);

  useEffect(() => {
    const isBasicStudent = user?.subscription_name === 'Basic Subscription' && user?.user_type === "STUDENT" && user?.free_trial === false;
    const isApiKeyMissing = !getApiKey();
    const isOnUrlPage = window.location.pathname.includes(`/${url}/`);
    const isOnNotesPage = window.location.pathname.includes('/notes/');
    const isOnSignupPage = window.location.pathname.includes('signup');
    const isModuleLawValid = moduleLaw?.includes('law');
  
    if (isBasicStudent || isApiKeyMissing) {
      if (
        (!window.location.pathname.includes('/blogs/') && isOnUrlPage && !window.location.pathname.includes('content-creator') && !isOnSignupPage) ||
        (isOnNotesPage && isModuleLawValid)
      ) {
        setCallToAction(true);
      } else {
        setCallToAction(false);
      }
    } else {
      setCallToAction(false);
    }
  }, [locate, window.location.pathname, moduleLaw, user, url]);

  useEffect(() => {
    setInterval(() => {
      if (
        (window.location.pathname === "/" && !getApiKey()) ||
        (getApiKey() &&
          user?.subscription_name === "Basic Subscription" &&
          (user?.free_trial === true || user?.free_trial === false))
      ) {
        let floating = document.getElementsByClassName(
          "reviews-io-floating-widget"
        );
        floating[0].style.display = "block";
      } else {
        let floating = document.getElementsByClassName(
          "reviews-io-floating-widget"
        );
        floating[0].style.display = "none";
      }
    }, 3000);
  }, []);

  // const handleSideNav = () => {
  //   console.log(marginState, "margin")
  //   setMarginState(marginState)
  // }

  return (
    // <BrowserRouter history={history}>
    <Switch>
      {key && getApiKey() ? (
        <>
        {!window.location.pathname.includes('aitutor') && <SideNavComponent
            marginState={marginState}
            setMarginState={setMarginState}
          />}
          
          <div
            style={
              marginState && window.innerWidth < 767
                ? {
                  width: "100%",
                  marginLeft: 0
                }
                : marginState && window.innerWidth > 768
                  ? {
                    marginLeft: '240px',
                    transition: "margin-left 0.1s ease-in-out"
                  }
                  : {
                    marginLeft: "50px",
                    transition: "margin-left 0.1s ease-in-out"
                  }}
          >
            <Header />
            <PrivateRoute component={MainAdminPage} path="/" exact />
            <PrivateRoute
              component={SignUpStepZero}
              path="/signup/step-zero"
              exact
            />
            <PrivateRoute
              component={SignUpStepOne}
              path="/signup/step-one"
              exact
            />
            <PrivateRoute
              component={SignUpStepTwo}
              path="/signup/step-two"
              exact
            />
            <PrivateRoute
              component={SignUpStepThree}
              path="/signup/step-three"
              exact
            />
            <PrivateRoute component={ProfilePage} path="/profile" exact />
            <PrivateRoute component={NotesPage} path="/:slug/notes" exact />
            <PrivateRoute
              component={SubscriptionCancellation}
              path="/subscription-cancel"
              exact
            />

            <PrivateRoute
              component={SubscriptionPage}
              path="/subscription"
              exact
            />
            <PrivateRoute
              component={AllSubscription}
              path="/all-subscriptions"
              exact
            />
            <PrivateRoute
              component={TermsConditions}
              path="/terms-conditions"
              exact
            />
            <PrivateRoute
              component={termsConditionsContent}
              path="/terms-conditions-content"
              exact
            />
            <PrivateRoute component={StudyTips} path="/study-tips" exact />
            <PrivateRoute component={YourLibraryPage} path="/library" exact />
            <PrivateRoute
              component={TutoringServices}
              path="/boost-your-grades"
              exact
            />
            <PrivateRoute
              component={StepZero}
              path="/content-creator/step-zero"
              exact
            />
            <PrivateRoute
              component={BecomeCreatorStepOne}
              path="/content-creator/step-one"
              exact
            />
            <PrivateRoute
              component={BecomeCreatorStepTwo}
              path="/content-creator/step-two"
              exact
            />
            <PrivateRoute
              component={BecomeCreatorStepThree}
              path="/content-creator/step-three"
              exact
            />
            <PrivateRoute
              component={PlanYourStudyModule}
              path="/content-creator/study-module"
              exact
            />
            <PrivateRoute
              component={ContentCreatorPortal}
              path="/content-creator/my-portal"
              exact
            />
            <PrivateRoute
              component={CookiesPolicy}
              path="/cookies-policy"
              exact
            />
            <PrivateRoute
              component={PrivacyPolicy}
              path="/privacy-policy"
              exact
            />
            <PrivateRoute
              component={ContentWelcome}
              path="/contentwelcome"
              exact
            />
            <PrivateRoute component={OurPartners} path="/our-partners" exact />
            <PrivateRoute
              component={StudyModulePage}
              path="/study-module/:slug"
              exact
            />
            <PrivateRoute
              component={FlashCards}
              path="/:slug/flashcards"
              exact
            />
            <PrivateRoute
              component={OneToOne}
              path="/book-tutorials/appointment/:type"
              exact
            />
            <PrivateRoute
              component={OneToOnePayment}
              path="/book-tutorials/one-to-one-payment"
              exact
            />
            <PrivateRoute component={ContactUs} path="/contact-us" exact />
            <PrivateRoute component={AboutUs} path="/about-us" exact />
            <PrivateRoute
              component={BookTutorialsGroup}
              path="/book-tutorials/group"
              exact
            />
            <PrivateRoute
              component={TutorSelect}
              path="/tutoring/tutor-select/:slug"
              exact
            />
            <PrivateRoute
              component={TutoringSelection}
              path="/tutoring/tutor-selection/:slug"
              exact
            />
            <PrivateRoute
              component={PreviewNotes}
              path="/:slug1/notes/:slug2"
              exact
            />
            <PrivateRoute
              component={PreviewEssay}
              path="/:slug1/model-essays/:slug2"
              exact
            />
            <PrivateRoute
              component={ModelEssays}
              path="/:slug/model-essays"
              exact
            />
            <PrivateRoute
              component={TutorDashboard}
              path="/tutor-dashboard"
              exact
            />
            <PrivateRoute component={TimeSlots} path="/time-slots" exact />
            <PrivateRoute component={Sessions} path="/sessions" exact />
            <PrivateRoute
              component={SessionDetail}
              path="/session-detail"
              exact
            />
            <PrivateRoute component={GrabDetail} path="/grab-detail" exact />
            <PrivateRoute component={Review} path="/review-and-feed" exact />
            <PrivateRoute component={CancelOrder} path="/cancel" exact />
            <PrivateRoute component={SuccessOrder} path="/success" exact />
            <PrivateRoute component={CheckoutPage} path="/checkout" exact />
            <PrivateRoute
              component={ShoppingBasket}
              path="/shopping-basket"
              exact
            />
            <PrivateRoute
              component={UserSessions}
              path="/user-sessions"
              exact
            />
            <PrivateRoute
              component={ContentCreatorDashboard}
              path="/content-creator/my-dashboard"
              exact
            />
            <PrivateRoute component={ContentCreatorShared} path="/content-creator/:slug/shared/" exact />
            <PrivateRoute
              component={MoreContent}
              path="/content-creator/more-content"
              exact
            />
            <PrivateRoute
              component={BecomeContentCreator}
              path="/content-creator"
              exact
            />
            <PrivateRoute
              component={StripeCheckoutPage}
              path="/stripe-checkout"
              exact
            />
            <PrivateRoute component={QuizResults} path="/quiz-results" exact />
            <PrivateRoute
              component={CaseSummaries}
              path="/case-studies/:slug"
              exact
            />
            {locate.endsWith("/") && (
              <PrivateRoute component={type.registered} path="/:slug/" exact />
            )}
            <PrivateRoute
              component={TutorialVideoFreeUsers}
              path="/:slug1/tutorial-videos"
              exact
            />
            <PrivateRoute component={QuizList} path="/:slug1/quizzes" exact />
            <PrivateRoute component={PaidUsersQuiz} path="/quiz/:slug" exact />
            <PrivateRoute component={ContentReceipt} path="/content-receipts" exact />
            <PrivateRoute component={PaymentMethod} path="/payment-methods" exact />
            <PrivateRoute component={Blogs} path="/blogs" exact />
            <PrivateRoute component={BlogsDetail} path="/blogs/:slug/" exact />
            <Footer />
            {callToAction === true && <CallToAction />}
          </div>
        </>
      ) : (
        <>
        {!window.location.pathname.includes('aitutor') ?
          <SideNavComponent
            marginState={marginState}
            setMarginState={setMarginState}
          /> : <AISideNav marginState={marginState}
          setMarginState={setMarginState}/>}
          <div
            style={
              marginState && window.innerWidth < 767
                ? {
                  width: "100%",
                  marginLeft: 0
                }
                : marginState && window.innerWidth > 768
                  ? {
                    marginLeft: '240px',
                    transition: "margin-left 0.1s ease-in-out"
                  }
                  : {
                    marginLeft: "50px",
                    transition: "margin-left 0.1s ease-in-out"
                  }
            }
          >
            {(window?.location?.pathname !== "/login" || window.location.pathname !== '/aitutor') && <Headers /> }
            <PublicRoute component={SignUpMain} path="/signup" exact />
            <PublicRoute component={LoginPage} path="/login" exact />
            <PublicRoute component={MainPage} path="/" exact />

            <PublicRoute
              component={ForgetPassword}
              path="/forget-password"
              exact
            />
            <PublicRoute
              component={ConfirmPassword}
              path="/auth/reset-password/:uid/:token"
              exact
            />
            <PublicRoute
              component={SubscriptionPage}
              path="/subscription"
              exact
            />
            <PublicRoute
              component={AllSubscription}
              path="/all-subscriptions"
              exact
            />
            <PublicRoute
              component={TermsConditions}
              path="/terms-conditions"
              exact
            />
            <PublicRoute
              component={termsConditionsContent}
              path="/terms-conditions-content"
              exact
            />
            <PublicRoute component={StudyTips} path="/study-tips" exact />
            <PublicRoute
              component={BecomeCreatorStepOne}
              path="/content-creator/step-one"
              exact
            />
            <PublicRoute
              component={BecomeCreatorStepTwo}
              path="/content-creator/step-two"
              exact
            />
            <PublicRoute
              component={BecomeCreatorStepThree}
              path="/content-creator/step-three"
              exact
            />
            <PublicRoute
              component={PlanYourStudyModule}
              path="/content-creator/study-module"
              exact
            />
            <PublicRoute
              component={ContentCreatorPortal}
              path="/content-creator/my-portal"
              exact
            />
            <PublicRoute
              component={CookiesPolicy}
              path="/cookies-policy"
              exact
            />
            <PublicRoute
              component={PrivacyPolicy}
              path="/privacy-policy"
              exact
            />
            <PublicRoute
              component={ContentWelcome}
              path="/contentwelcome"
              exact
            />
            <PublicRoute component={OurPartners} path="/our-partners" exact />
            <PublicRoute
              component={StudyModulePage}
              path="/study-module/:slug"
              exact
            />
            <PublicRoute
              component={FlashCards}
              path="/:slug/flashcards"
              exact
            />
            <PublicRoute
              component={OneToOne}
              path="/book-tutorials/appointment/:type"
              exact
            />
            <PublicRoute
              component={OneToOnePayment}
              path="/book-tutorials/one-to-one-payment"
              exact
            />
            <PublicRoute
              component={TutorSelect}
              path="/tutoring/tutor-select/:slug"
              exact
            />
            <PublicRoute component={ContactUs} path="/contact-us" exact />
            <PublicRoute component={AboutUs} path="/about-us" exact />
            <PublicRoute
              component={PreviewNotes}
              path="/:slug1/notes/:slug2"
              exact
            />
            <PublicRoute
              component={PreviewEssay}
              path="/:slug1/model-essays/:slug2"
              exact
            />
            <PublicRoute
              component={ModelEssays}
              path="/:slug/model-essays"
              exact
            />
            <PublicRoute
              component={TutorDashboard}
              path="/tutor-dashboard"
              exact
            />
            <PublicRoute component={TimeSlots} path="/time-slots" exact />
            <PublicRoute component={Sessions} path="/sessions" exact />
            <PublicRoute
              component={SessionDetail}
              path="/session-detail"
              exact
            />
            <PublicRoute component={CancelOrder} path="/cancel" exact />
            <PublicRoute component={SuccessOrder} path="/success" exact />
            <PublicRoute component={CheckoutPage} path="/checkout" exact />
            <PublicRoute
              component={ShoppingBasket}
              path="/shopping-basket"
              exact
            />
            <PublicRoute component={AITutor} path="/aitutor" exact />
            <PublicRoute component={UserSessions} path="/user-sessions" exact />
            <PublicRoute
              component={ContentCreatorDashboard}
              path="/content-creator/my-dashboard"
              exact
            />
            <PublicRoute component={ContentCreatorShared} path="/content-creator/:slug/shared/" exact />
            <PublicRoute
              component={MoreContent}
              path="/content-creator/more-content"
              exact
            />
            <PublicRoute
              component={BecomeContentCreator}
              path="/content-creator"
              exact
            />
            <PublicRoute
              component={StripeCheckoutPage}
              path="/stripe-checkout"
              exact
            />
            <PublicRoute
              component={CaseSummaries}
              path="/case-studies/:slug"
              exact
            />
            <PublicRoute component={type.nonRegistered} path="/:slug1/" exact />
            <PublicRoute
              component={TutorialVideoFreeUsers}
              path="/:slug1/tutorial-videos"
              exact
            />
            <PublicRoute component={QuizList} path="/:slug1/quizzes" exact />
            <PublicRoute component={NotesPage} path="/:slug/notes" exact />
            <PublicRoute component={PaidUsersQuiz} path="/quiz/:slug" exact />
            <PublicRoute
              component={QuizResults}
              path="/quiz-results/:slug"
              exact
            />
            <PublicRoute
              component={TutoringServices}
              path="/boost-your-grades"
              exact
            />
            <PublicRoute component={Blogs} path="/blogs" exact />
            <PublicRoute component={BlogsDetail} path="/blogs/:slug/" exact />
            {footer && <Footer />}
            {/* {callToAction === true && <CallToAction />} */}
          </div>
        </>
      )}
    </Switch>
    // </BrowserRouter>
  );
}

export default App;
