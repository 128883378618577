import React, { useEffect, useState } from "react";
import Container from "../../components/container";
import Input from "../../components/Input/InputForm";
import Button from "../../components/EmptyButton";
import { connect, useDispatch } from "react-redux";
import { contactUsAction, createErrorAlert } from "../../redux/actions";
import { Helmet } from "react-helmet";
import { contactUsData } from "../../redux/selectors";

const ContactUs = (props) => {
  const { postContactUs } = props;

  const [initialState, setInitialState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [emptyState, setEmptyState] = useState({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });
  const [emailRegex, setEmailRegex] = useState(false);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const sendQuery = async () => {
    if (
      initialState.firstName.length === 0 ||
      initialState.lastName.length === 0 ||
      initialState.email.length === 0 ||
      initialState.message.length === 0
    ) {
      setEmptyState({
        firstName: initialState.firstName.length === 0 ? true : false,
        lastName: initialState.lastName.length === 0 ? true : false,
        email: initialState.email.length === 0 ? true : false,
        message: initialState.message.length === 0 ? true : false,
      });
      return;
    }
    let validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (initialState.email.match(validRegex)) {
      setLoader(true);
      postContactUs({
        first_name: initialState.firstName,
        last_name: initialState.lastName,
        message: initialState.message,
        email: initialState.email,
      }, setInitialState);
      dispatch(createErrorAlert(201, "Thank You", "We received your message. We will respond as soon as possible.", 201));
      setLoader(false);
      setEmptyState({
        firstName: false,
        lastName: false,
        email: false,
        message: false,
      });
      setEmailRegex(false);
    } else {
      setEmailRegex(true);
      setEmptyState({
        firstName: false,
        lastName: false,
        email: false,
        message: false,
      });
    }
  };

  const textData = (textArea) => {
    setInitialState((prevState) => ({
      ...prevState,
      message: textArea,
    }));
  };

  return (
    <div className="bg-white">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Contact Us</title>
        <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you. Save time and Study more efficiently. We have simple study resources created by top students and graduates." />
        <link rel="canonical" href="https://simplestudying.com/contact-us" />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simple Studying | Contact Us" />
        <meta property="og:description" content="Get High Grades with the support of top graduates who took the same courses as you.
Save time and Study more efficiently. We have simple study resources created by top students and graduates." />
        <meta
          property="og:url"
          content="https://simplestudying.com/contact-us"
        />
        <meta property="og:site_name" content="StudyCrowd.AI" />
      </Helmet>
      <div className="bg-offWhite">
        <Container>
          <p className="text-h5 custom2:text-h3 text-darkest font-bold mt-16 mb-9">
            Contact Us
          </p>
          <div className="flex flex-col text-darkest">
            <div className="flex flex-col md:flex-row md:space-x-6 mb-9 ">
              <div className="w-full mb-6 md:mb-0 md:w-2/4">
                <label className="mb-8">First Name</label>
                <Input
                  placeholder=""
                  value={initialState.firstName}
                  setValue={(value) => {
                    setInitialState((prevState) => ({
                      ...prevState,
                      firstName: value,
                    }));
                    setEmptyState((prevState) => ({
                      ...prevState,
                      firstName: false,
                    }));
                  }}
                />
                {emptyState.firstName && (
                  <p style={{ color: "red" }}>* First Name is required</p>
                )}
              </div>
              <div className="w-full md:w-2/4">
                <label className="mb-8">Last Name</label>
                <Input
                  placeholder=""
                  value={initialState.lastName}
                  setValue={(value) => {
                    setInitialState((prevState) => ({
                      ...prevState,
                      lastName: value,
                    }));
                    setEmptyState((prevState) => ({
                      ...prevState,
                      lastName: false,
                    }));
                  }}
                />
                {emptyState.lastName && (
                  <p style={{ color: "red" }}>* Last Name is required</p>
                )}
              </div>
            </div>

            <div className="w-full mb-9">
              <label className="mb-8">Email</label>
              <Input
                placeholder=""
                value={initialState.email}
                setValue={(value) => {
                  setInitialState((prevState) => ({
                    ...prevState,
                    email: value,
                  }));
                  setEmptyState((prevState) => ({
                    ...prevState,
                    email: false,
                  }));
                }}
              />
              {emptyState.email && (
                <p style={{ color: "red" }}>* Email is required</p>
              )}
              {emailRegex && (
                <p style={{ color: "red" }}>* Email is not Valid</p>
              )}
            </div>

            <div className="w-full mb-9">
              <label className="mb-8">Message</label>
              <textarea
                id="textArea"
                className="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                style={{ border: "1px solid #D8D8D8" }}
                onChange={(e) => {
                  textData(e.target.value);
                  setEmptyState((prevState) => ({
                    ...prevState,
                    message: false,
                  }));
                }}
                value={initialState.message}
                placeholder="Type your message here..."
              />
              {emptyState.message && (
                <p style={{ color: "red" }}>* Message is required</p>
              )}
            </div>
          </div>
          <Button
            filled
            className="queryBtn"
            onClick={sendQuery}
            loading={loader}
          >
            <p className="text-center text-small-text xl:text-body-large">
              Send Query
            </p>
          </Button>

          <p
            style={{ fontWeight: "bold", textAlign: "center" }}
            className="mt-10 alterText"
          >
            Alternatively, you can contact customer service by email at
            info@studycrowd.ai
          </p>
          {/*<div className="checkbox flex items-center ">*/}
          {/*    <input type="checkbox" className="input-assumpte" id="input-confidencial" checked={true}/>*/}
          {/*    <label for="input-confidencial"*/}
          {/*           className="text-16 custom2:w-10/12 md:text-body-large flex items-center">Remember*/}
          {/*        this card</label>*/}
          {/*</div>*/}
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  contactUsData: contactUsData(state),
});
const mapDispatchToProps = (dispatch) => ({
  postContactUs: (data, setInitialState) => dispatch(contactUsAction(data, setInitialState)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
