import { apiRequest } from ".";

export default function fetchEssays(finalSlug, studyModuleRes, setLoadingState, setDocument, setStudyModuleState, setOpenModule) {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await apiRequest({
                method: "get",
                url: `content/essays/search/?essays=${finalSlug}`,
            });

            console.log('abccc')
            setLoadingState(false);

            for (let i = 0; i < studyModuleRes.length; i++) {
                if (parseInt(res?.data[0]?.study_module) === parseInt(studyModuleRes[i].id)) {
                    if (res?.data[0]?.slug === finalSlug) {
                        if (res.data[0]?.content !== '') {
                            setDocument(false);
                        }
                        setStudyModuleState(studyModuleRes[i].title);
                        setOpenModule(studyModuleRes[i])
                        resolve(res);
                    }
                }
            }

            resolve(null);
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
}