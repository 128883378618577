import React, { useContext, useEffect, useState } from "react";
import Container from "../../components/container";
import CalendarCard from "../../components/Calendar/Calendar";
import CustomModal from "../../components/Modal";
import { useHistory, useLocation } from "react-router-dom";
import Stepper from "../../components/SignUpStepper";
import {
  getHolidaysData,
  tutorAvailabilityData,
  tutorAvailabilityError,
  tutorAvailabilityLoading,
  tutorData,
} from "../../redux/selectors";
import {
  createErrorAlert,
  getHolidays,
  getTutor,
  getTutorAvailability,
} from "../../redux/actions";
import { connect, useDispatch } from "react-redux";
import Button from "../../components/EmptyButton";
import { Helmet } from "react-helmet";
import Context from "../../services/Context";
import Select from "../../components/Select";
import mariamImg from "../../assets/images/mariamImg.svg";
import editImg from "../../assets/icons/edit-icn.svg";
import watchImg from "../../assets/icons/watch-icn.svg";
import cameraImg from "../../assets/icons/camera-icn.svg";
import delImg from "../../assets/icons/del-icn.svg";
import calImg from "../../assets/icons/cal-icn.svg";
import Calendly from "../../components/CalendalyCalendar/Calendly";
import TimezoneSelect from "../../components/reactTimezone/TimezoneSelect";
import { Modal } from "react-responsive-modal";
import moment from "moment-timezone";
import { getUser } from "../../helpers";

const OneToOne = ({
  loading,
  data,
  getTutorAvailability,
  getTutor,
  tutor,
  getTutorHoliday,
  holiday,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { value, updateValue } = useContext(Context);

  const { selectedPackages, selectedModule, dataItem, selectedSlots, claim } =
    value;

  const renderedSlots = new Set();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dates, setDates] = useState([]);
  const [calendarDate, setcalendarDate] = useState("");
  const [tutorModal, setTutorModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedTutor, setSelectedTutor] = useState(null);
  const [selectedModules, setSelectedModules] = useState({});
  const [modules, setModules] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [next, setNext] = useState(true);
  const [time, setTime] = useState("");
  const [id, setId] = useState("");
  const [optionModal, setOptionModal] = useState(false)
  const [remaining, setRemaining] = useState(0)
  const [selectedTimezone, setSelectedTimezone] = useState("Europe/London");
  const [user, setUser] = useState(JSON.parse(getUser()));
  const [notSelected, setNotSelected] = useState(
    Array.from(
      {
        length:
          selectedPackages !== undefined && claim !== "free"
            ? selectedPackages?.package_recurence
            : user?.credit,
      },
      () => null // Explicitly populate the array with null values
    )
  );

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";

      const confirmationMessage =
        "Are you sure you want to leave this page? Your unsaved changes may be lost.";
      (e || window.event).returnValue = confirmationMessage;

      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    getTutorHoliday(dataItem?.name);
  }, []);

  useEffect(() => {
    if (value?.studyModulesData) {
      if (value?.studyModulesData?.length > 0) {
        let recom = [];
        value?.studyModulesData?.map((item) => {
          if (
            item.slug !== "macroeconomics" &&
            item.slug !== "microeconomics" &&
            item.slug !== "business" &&
            item.slug !== "marketing" &&
            item.slug !== "our-company" &&
            item.slug !== "useful-articles" &&
            item.slug !== "uncategorized" &&
            item.slug !== "test" &&
            item.slug !== "partners"
          ) {
            recom.push({
              value: item,
              label: item.title,
            });
          }
        });
        // eslint-disable-next-line no-undef
        let sortedd = _.sortBy(recom, "label");
        setModules(sortedd);
        let arr = [];
        let filter = recom.slice(0, 10);
        filter.forEach((e) => {
          if (
            e.value.slug !== "macroeconomics" &&
            e.value.slug !== "microeconomics" &&
            e.value.slug !== "business" &&
            e.value.slug !== "marketing" &&
            e.value.slug !== "our-company" &&
            e.value.slug !== "useful-articles" &&
            e.value.slug !== "uncategorized" &&
            e.value.slug !== "test" &&
            e.value.slug !== "partners"
          ) {
            arr.push(e);
            arr.sort();
          }
        });
        // eslint-disable-next-line no-undef
        let sortedTen = _.sortBy(arr, "label");
        for (let i = 0; i < sortedTen.length; i++) {
          sortedData.push(sortedTen[i]);
        }
      }
    }
  }, [value?.studyModulesData]);

  useEffect(() => {
    let stripeRedirect = sessionStorage.getItem("stripRedirect");
    if (stripeRedirect === "true") {
      sessionStorage.removeItem("stripRedirect");
      history.push("/");
    }
    if (isModalOpen) {
      document.body.style.overflowY = "hidden";
      document.body.style.position = "fixed";
    } else {
      document.body.style.overflowY = "unset";
      document.body.style.position = "unset";
    }
  }, [isModalOpen]);

  const handleProceed = () => {
    const recurrence = selectedPackages !== undefined ? parseInt(selectedPackages?.package_recurence) : user?.credit
    if((selectedSlots?.length || dates?.length) < recurrence){
      setOptionModal(true)
      setRemaining(recurrence - (selectedSlots?.length || dates?.length))
      return
    } else{
      confirmClick()
    }
  }

  const confirmClick = () => {
    // if (
    //   (dates?.length || selectedSlots?.length) <
    //   parseInt(selectedPackages !== undefined ? selectedPackages?.package_recurence : 1)
    // ) {
    //   if(selectedPackages !== undefined){
    //     if (selectedPackages?.package_recurence === "3") {
    //       dispatch(
    //         createErrorAlert(
    //           200,
    //           "Error",
    //           `Please select date and time for all the three hours of tutoring session. Remaining ${
    //             parseInt(selectedPackages?.package_recurence) -
    //             parseInt(dates.length)
    //           }`,
    //           400
    //         )
    //       );
    //       return;
    //     } else if (selectedPackages?.package_recurence === "6") {
    //       dispatch(
    //         createErrorAlert(
    //           200,
    //           "Error",
    //           `Please select date and time for all the six hours of tutoring session. Remaining ${
    //             parseInt(selectedPackages?.package_recurence) -
    //             parseInt(dates.length)
    //           }`,
    //           400
    //         )
    //       );
    //       return;
    //     } else if (selectedPackages?.package_recurence === "1") {
    //       dispatch(
    //         createErrorAlert(
    //           200,
    //           "Error",
    //           "Please select date and time for one hour of tutoring session",
    //           400
    //         )
    //       );
    //       return;
    //     } else if (dates.length === 0) {
    //       dispatch(
    //         createErrorAlert(200, "Error", "Please Select any Date and Time", 400)
    //       );
    //       return;
    //     }
    //   } else{
    //     dispatch(
    //       createErrorAlert(
    //         200,
    //         "Error",
    //         "Please select date and time for one hour of tutoring session",
    //         400
    //       )
    //     );
    //   }
    // } else {
    //   updateValue({
    //     ...value,
    //     dates: selectedSlots,
    //     timeZone: selectedTimezone,
    //   });
    //   history.push({
    //     pathname: `/book-tutorials/one-to-one-payment`,
    //   });
    // }
    const filteredSelectedSlots = selectedSlots.filter(
      (slot) => slot.date !== "No Date Time Selected"
    );

    updateValue({
      ...value,
      dates: filteredSelectedSlots,
      timeZone: selectedTimezone,
    });
    history.push({
      pathname: `/book-tutorials/one-to-one-payment`,
    });
  };

  const handleCheckboxChange = (time, convertedTime, id, slotId) => {
    setTime(convertedTime);
    setId(slotId);
    setSelectedTimes([time]);
  };

  const handleDateChange = (date) => {
    setSelectedTimes([]);
    if (!selectedTutor?.name && !dataItem?.name) {
      dispatch(createErrorAlert(200, "", "Please Select a tutor first", 200));
      return;
    }
    if (
      parseInt(
        selectedPackages !== undefined ? selectedPackages?.package_recurence : 1
      ) === (dates?.length || selectedSlots?.length)
    ) {
      dispatch(
        createErrorAlert(
          200,
          "",
          "You can't select further dates according to your package selection",
          200
        )
      );
      return;
    }
    setSelectedDates(date);
    let month = parseInt(date.getMonth() + 1);
    let selectedDate;
    if (date.getMonth() + 1 < 10) {
      selectedDate =
        date.getFullYear() + "-" + 0 + month + "-" + date.getDate();
    }
    if (date.getDate() < 10) {
      selectedDate =
        date.getFullYear() + "-" + month + "-" + 0 + date.getDate();
    }
    if (date.getDate() < 10 && date.getMonth() + 1 < 10) {
      selectedDate =
        date.getFullYear() + "-" + 0 + month + "-" + 0 + date.getDate();
    }
    if (date.getMonth() + 1 >= 10) {
      selectedDate = date.getFullYear() + "-" + month + "-" + date.getDate();
    }
    setcalendarDate(selectedDate);
    getTutorAvailability(
      selectedTutor?.name || dataItem?.name,
      selectedDate
    ).then((res) => {
      setTimeSlots(res.data);
    });
  };

  const handleSelectedModule = (value) => {
    setSelectedModules(value);
    updateValue((prev) => ({
      ...prev,
      selectedModule: value,
    }));
  };

  const handleTutorSelect = (item) => {
    setSelectedTutor(item);
    updateValue((prev) => ({
      ...prev,
      dataItem: item,
      selectedSlots: [],
    }));
    setDates([]);
    setTime("");
    setNext(true);
    setSelectedTimes([]);
  };

  const handleNextClick = () => {
    const selectedDate = new Date(selectedDates);
    selectedDate.setDate(selectedDate.getDate() + 1);
    const newDate = selectedDate.toISOString().split("T")[0];
    
    // Check for duplicate
    const isDuplicate = dates.some(
      (entry) => entry.startTime === time && entry.date === newDate
    );
  
    if (!isDuplicate) {
      const totalSlots =
        selectedPackages?.package_recurence || user?.credit || 1;
  
      // Create new date entry
      const newDates = [
        ...dates,
        { startTime: time, date: newDate, availablity_id: id },
      ];
  
      // Calculate remaining placeholders
      const remainingSlots = totalSlots - newDates.length;
  
      // Update notSelected and filledArray dynamically
      const updatedNotSelected = Array.from(
        { length: Math.max(0, remainingSlots) },
        () => null
      );
  
      const newArr = [...newDates, ...updatedNotSelected];
  
      setDates(newDates);
      setNotSelected(updatedNotSelected);
      updateValue((prev) => ({
        ...prev,
        selectedSlots: newDates,
      }));
      setNext(false);
  
      // If only one slot left, disable next button
      if (remainingSlots === 1) {
        setNext(false);
        return;
      }
    } else {
      // Handle duplicate selection
      dispatch(
        createErrorAlert(
          400,
          "",
          "You can't select the same time on the same date",
          400
        )
      );
    }
  
    // Reset time and selectedTimes
    setTime("");
    setSelectedTimes([]);
  };
  

  const convertToSelectedTimezone = (slotTime) => {
    try {
      const slotWithDate = `1970-01-01 ${slotTime}`;
      return moment
        .tz(slotWithDate, "YYYY-MM-DD hh:mm A", moment.tz.guess())
        .tz(selectedTimezone)
        .format("hh:mm A");
    } catch (error) {
      console.error("Error in date conversion:", error);
      return "Invalid Date";
    }
  };

  const handleDeleteBooking = (item) => {
    const updatedDates = selectedSlots.filter(
      (entry) =>
        !(entry.startTime === item.startTime && entry.date === item.date)
    );
    setDates(updatedDates);
    updateValue((prev) => ({
      ...prev,
      selectedSlots: updatedDates,
    }));
    setNext(true);
  };

  const filledArray = [
    ...dates,
    ...Array.from(
      {
        length:
          selectedPackages !== undefined
            ? selectedPackages.package_recurence
            : claim === "free"
            ? notSelected.length
            : 0,
      },
      () => ({
        date: "No Date Time Selected",
      })
    ),
  ];

  return (
    <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
      <Stepper step={3} exit={() => history.push("/profile")} totalSteps={4} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Appointment Date</title>
        <meta
          name="description"
          content="Get High Grades with the support of top graduates who took the same courses as you"
        />
        <link
          rel="canonical"
          href="https://simplestudying.com/book-tutorials/appointment/one-to-one"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simple Studying | One To One" />
        <meta
          property="og:description"
          content="Get High Grades with the support of top graduates who took the same courses as you"
        />
        <meta
          property="og:url"
          content="https://simplestudying.com/book-tutorials/appointment/one-to-one"
        />
        <meta property="og:site_name" content="StudyCrowd.AI" />
      </Helmet>
      <div>
        <Container>
          <div className="pt-4 md:pt-10 lg:pt-14 flex">
            <div className="text-darkest schduelSec">
              <p className="text-36 font-bold mb-12 ">Schedule a Session</p>
            </div>
          </div>
          <div>
            <div>
              <div className="bulletSection">
                <h5 className="holdBullet">Holiday</h5>
                {/* <h5 className="bookBullet">Booked</h5> */}
              </div>
              <div className="mt-6 flex flex-col items-center">
                <p className="text-center text-h5 font-bold">
                  Please select any of the available dates on the calendar below
                  and choose the time slot:
                </p>
                <p className="text-center text-19 mt-6 max-w-5xl w-full">
                  You can select as many dates and times as your tutoring
                  package includes. Alternatively, you can select just one date
                  and time for your first tutoring. The rest of the hours will
                  be added as a credit to your account that you can use anytime
                </p>
              </div>
              <div className="flex flex-wrap summmary-sec">
                <div className="mr-36">
                  <div className="md:w-56 lg:w-80 mb-5 pb-5 summary-border">
                    <p className="text-26 font-bold  mb-9 mt-5">Summary</p>
                    <Select
                      placeholder="Select Study Module"
                      values={modules}
                      isClearable={false}
                      value={
                        Object.keys(selectedModules).length !== 0
                          ? selectedModules
                          : Object.keys(selectedModule).length !== 0
                          ? selectedModule
                          : ""
                      }
                      handleSelected={handleSelectedModule}
                    />
                  </div>
                  <div className="md:w-56 lg:w-80 mb-5 pb-5 summary-border">
                    <div className="w-70 h-94  rounded-img">
                      <img
                        src={selectedTutor?.image ? selectedTutor?.image.replace('https://backend.simplestudying.com', 'https://backend.studycrowd.ai') : dataItem?.image ? dataItem?.image.replace('https://backend.simplestudying.com', 'https://backend.studycrowd.ai') : null}
                        className="mt-7 w-full h-full mb-4 rounded-full"
                      />
                    </div>
                    <h1 className="text-24  mb-5">
                      {selectedTutor?.name || dataItem?.name}
                    </h1>
                    <p className="text-18 w-64">
                      {selectedTutor?.education || dataItem?.education}
                    </p>
                  </div>
                  <div className="md:w-56 lg:w-80 mb-5 pb-8 summary-border">
                    <div className="flex justify-between gap-3 mb-7">
                      <p className="text-24 font-bold text-primary">
                        Tutoring Sessions
                      </p>
                      {/* <button>
                        <img src={editImg} />
                      </button> */}
                    </div>
                    <div className="flex gap-4 mb-3">
                      <img src={watchImg} />
                      <p className="text-19">
                        {selectedPackages !== undefined
                          ? selectedPackages.title
                          : "Free"}
                      </p>
                    </div>
                    <p className="text-16 mb-7">
                      <strong class="text-primary">Note: </strong>Each tutoring
                      session is for 1 hour. You can book as many hours as you
                      need.
                    </p>
                    <div className="flex gap-4 mb-9 ">
                      <img src={cameraImg} className="h-5 mt-1" />
                      <p className="text-19">
                        Web conferencing details provided upon confirmation.
                      </p>
                    </div>
                    <button
                      onClick={() => {
                        getTutor(
                          selectedPackages !== undefined
                            ? selectedPackages.slug
                            : "free"
                        );
                        setTutorModal(true);
                      }}
                      class=" flex items-center fw-semibold text-primary text-16 text-center p-3 border-primary border-2 hover:text-white hover:bg-primary w-full justify-center"
                    >
                      Change Tutor
                    </button>
                  </div>
                  <div className="md:w-56 lg:w-80">
                    <p className="text-24 font-bold text-primary mb-6">
                      {selectedPackages !== undefined
                        ? selectedPackages.title
                        : "Free"}
                    </p>
                    {filledArray?.length > 0
                      ? filledArray?.map((item, index) => {
                          return (
                            <div className="flex gap-4 mb-6" key={index}>
                              <img src={calImg} className="mb-auto mt-1" />
                              <p className="text-18 max-w-xs w-full">
                                {item.startTime ? `${item.startTime}, ` : ""}
                                {item.date}
                              </p>
                              {item.date !== "No Date Time Selected" && (
                                <button className="mb-auto mt-1">
                                  <img
                                    src={delImg}
                                    onClick={() => {
                                      handleDeleteBooking(item);
                                    }}
                                  />
                                </button>
                              )}
                            </div>
                          );
                        })
                      : selectedSlots?.length > 0
                      ? selectedSlots?.slice(0, selectedPackages !== undefined ? selectedPackages.package_recurence : user?.credit).map((item, index) => {
                          return (
                            <div className="flex gap-4 mb-6" key={index}>
                              <img src={calImg} className="mb-auto mt-1" />
                              <p className="text-18 max-w-xs w-full">
                                {item.startTime}, {item.date}
                              </p>
                              <button className="mb-auto mt-1">
                                <img
                                  src={delImg}
                                  onClick={() => {
                                    handleDeleteBooking(item);
                                  }}
                                />
                              </button>
                            </div>
                          );
                        })
                      : notSelected.length > 0 &&
                        notSelected.slice(0, selectedPackages !== undefined ? selectedPackages.package_recurence : user?.credit).map((_, index) => (
                          <div className="flex gap-4 mb-6" key={index}>
                            <img src={calImg} className="mb-auto mt-1" />
                            <p className="text-18 max-w-xs w-full" key={index}>
                              No Date and time selected
                            </p>
                          </div>
                        ))}
                  </div>
                </div>
                <div className="flex gap-5 flex-wrap justify-between flex-grow summary-sec-2">
                  <div>
                    <p className="text-26 font-bold  mb-9 mt-70 text-center">
                      Select a Date & Time
                    </p>
                    <Calendly
                      handleDateChange={handleDateChange}
                      tutorHolidays={holiday}
                      selectedTutor={selectedTutor || dataItem}
                    />
                    <TimezoneSelect
                      selectedTimezone={selectedTimezone}
                      setSelectedTimezone={setSelectedTimezone}
                    />
                    {selectedSlots?.length > 0 && (
                      <button
                        onClick={handleProceed}
                        class=" bookBtn2 bookBtn w-345"
                      >
                        PROCEED TO CHECKOUT
                      </button>
                    )}
                  </div>
                  <div className="max-w-xs min-w-116 px-3 w-full">
                    <p className="text-19 mb-9 mt-52 media-p text-primary text-center">
                      {selectedDates
                        ? selectedDates.toDateString()
                        : "No date selected"}
                    </p>

                    {data.length > 0 &&
                      data.map((item, index) => {
                        if (
                          item.available_slot.length > 0 &&
                          selectedSlots?.length !==
                            parseInt(
                              selectedPackages !== undefined
                                ? selectedPackages.package_recurence
                                : user?.credit
                            ) &&
                          selectedDates !== null
                        ) {
                          return item.available_slot.map(
                            (subItem, subIndex) => {
                              const slot = subItem.slot.slot;
                              const slotId = item.availability_id;
                              const uniqueValue = `${slot}`; // Make the value unique
                              if (renderedSlots.has(slot)) {
                                return null;
                              }
                              renderedSlots.add(slot);
                              const convertedSlotTime =
                                convertToSelectedTimezone(slot);
                              return (
                                <div
                                  key={subIndex}
                                  className="flex items-center mb-2"
                                >
                                  <React.Fragment>
                                    <input
                                      type="radio"
                                      id={`time-${index}-${subIndex}`}
                                      name={`time-${index}`}
                                      value={uniqueValue}
                                      checked={selectedTimes.includes(
                                        uniqueValue
                                      )}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          uniqueValue,
                                          convertedSlotTime,
                                          index,
                                          slotId
                                        )
                                      }
                                      className="checkbox-input hidden"
                                    />
                                    <label
                                      htmlFor={`time-${index}-${subIndex}`}
                                      className="checkbox-label mr-2"
                                    >
                                      {convertedSlotTime}
                                    </label>
                                  </React.Fragment>
                                  {selectedTimes.includes(uniqueValue) && (
                                    <button
                                      type="button"
                                      onClick={() => handleNextClick()}
                                      className="nextButtonSlot bookBtn2 h-[20px] flex rounded-xl items-center fw-semibold text-16 text-center p-2 justify-center cursor-pointer"
                                    >
                                      Next
                                    </button>
                                  )}
                                </div>
                              );
                            }
                          );
                        } else {
                          if (index === 0) {
                            return (
                              <p
                                className="text-primary text-center"
                                key={index}
                              >
                                No Time Slot Available
                              </p>
                            );
                          }
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={"buttonCnfrm"}>
            <Button className="confrmBtn" filled >
              Confirm
            </Button>
          </div> */}
        </Container>
      </div>
      <Modal
        open={tutorModal}
        onClose={() => setTutorModal(false)}
        focusTrapped={false}
        center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "react-modal-class2",
        }}
      >
        <div>
          <p className="text-darkest md:text-h3 font-bold mb-5 text-center">
            Select a Tutor
          </p>
          <div className="flex gap-4 flex-wrap justify-center">
            {tutor[0]?.tutor?.length > 0
              ? tutor[0]?.tutor?.map((item) => {
                  return (
                    <div
                      className={`min-w-116 w-64 cursor-pointer pb-5 border-solid border-2 rounded-xl ${
                        item.name === selectedTutor?.name
                          ? "border-4 boderClrbrown"
                          : "border-ligthBorderGrey"
                      } p-4 `}
                      onClick={() => {
                        handleTutorSelect(item);
                      }}
                    >
                      <div className="w-70 h-94  rounded-img">
                        <img
                          src={item?.image}
                          className="mt-7 w-full h-full mb-4"
                        />
                      </div>
                      <h1 className="text-24  mb-5">{item?.name}</h1>
                      <p className="text-18">{item?.education}</p>
                    </div>
                  );
                })
              : null}
          </div>
          <div className="flex gap-5 mt-20">
            <button
              onClick={() => setTutorModal(false)}
              class=" flex items-center fw-semibold text-primary text-16 text-center p-3 border-primary border-2 hover:text-white hover:bg-primary w-full justify-center"
            >
              Cancel
            </button>
            <button
              value="submit"
              class="bookBtn2 bookBtn w-full"
              onClick={() => {
                setTutorModal(false);
              }}
            >
              Change Tutor
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={optionModal}
        onClose={() => setOptionModal(false)}
        focusTrapped={false}
        center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "react-modal-class2",
        }}
      >
        <div>
          <p className="text-20 mb-5">
            Your tutoring package includes {remaining} more sessions. You can either  
            schedule the remaining sessions now or at a later time.  
            Opting to schedule later? They'll be credited to your account, 
            ready for  you to use whenever suits you best!
          </p>
          <div className="flex space-x-8">
            <Button filled onClick={() => {
              setOptionModal(false)
            }}>
              <p>Select Now</p>
            </Button>
            <Button filled onClick={() => {
              setOptionModal(false)
              confirmClick()
            }}>
              <p>Select Later</p>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: tutorAvailabilityLoading(state),
  error: tutorAvailabilityError(state),
  data: tutorAvailabilityData(state),
  tutor: tutorData(state),
  holiday: getHolidaysData(state),
});
const mapDispatchToProps = (dispatch) => ({
  getTutorAvailability: (slugs, date) =>
    dispatch(getTutorAvailability(slugs, date)),
  getTutor: (tutorSlug) => dispatch(getTutor(tutorSlug)),
  getTutorHoliday: (name) => dispatch(getHolidays(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OneToOne);
