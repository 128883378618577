import * as t from '../types';
import {GetCsrf, GetUserInfo, UpdateUser} from '../../services';
import {setUser} from '../../helpers';
import {createErrorAlert} from './errorAlertAction';
import setCsrf from '../../helpers/setCsrf';

export const getUserInfo = () => async (dispatch) => {
    return await GetUserInfo()
        .then((data) => {
            dispatch({
                type: t.GET_USER,
                payload: data,
            });
            
            setUser(JSON.stringify(data));
            dispatch(addUser(data));
        })
        .catch((error) => {
            dispatch({
                type: t.GET_USER_FAILED,
                payload: error.response,
            });
            dispatch(createErrorAlert(error?.response?.status, 'Error', error?.response?.data?.detail || error?.response?.data, error?.response?.status));
        });
};

export const addUser = (user) => {
    return {
        type: 'ADD_USER',
        payload: user,
    };
    
};

export const deleteUser = () => {
    return {
        type: 'REMOVE_USER',
        payload: null,
    };
};
export const getUserCSRF = () => async (dispatch) => {
    return await GetCsrf()
        .then((data) => {
            dispatch({
                type: t.GET_CSRF,
                payload: data,
            });
            setCsrf(data.csrf);
            dispatch(addCsrf(data.csrf));
        })
        .catch((err) => {
            dispatch({
                type: t.GET_CSRF_FAILED,
                payload: err.response,
            });
        });
};
export const addCsrf = (csrf) => {
    return {
        type: 'ADD_CSRF',
        payload: csrf,
    };
};

export const deleteCsrf = () => {
    return {
        type: 'REMOVE_CSRF',
        payload: null,
    };
};

export const updateUser = (param) => async (dispatch) => {
    try {
        dispatch({ type: t.UPDATE_USER_REQUEST });

        const data = await UpdateUser(param); // Await the API call

        dispatch({
            type: t.UPDATE_USER_SUCCESS,
            payload: data,
        });

        if (window.location.pathname === "/profile") {
            setTimeout(() => {
                dispatch(createErrorAlert(201, "Success", "User Info has been Updated", 201));
            }, 3000);
        }

        setUser(JSON.stringify(data));

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !/webkit/i.test(navigator.userAgent);
        

        if (isSafari) {
            window.location.reload();
        } else {
            await dispatch(getUserInfo());
        }

    } catch (error) {
        dispatch({
            type: t.UPDATE_USER_FAILED,
            payload: error.response,
        });

        dispatch(
            createErrorAlert(
                error?.response?.status,
                "Error",
                error?.response?.data?.detail || error?.response?.data,
                error?.response?.status
            )
        );
    }
};
