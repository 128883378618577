import React, { useCallback, useContext, useEffect, useState } from "react";
import "./index.css";
import { debounce } from "lodash";
import { getApiKey } from "../../helpers";
import Logo from "../../assets/images/study_small.png";
import Burger from "../../assets/icons/burger.svg";
import { Modal } from "react-responsive-modal";
import apiRequest from "../../helpers/apiRequest";
import timeCheck from "../../helpers/timecheck";
import { FaBell, FaCalendar, FaCreditCard } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import Badge from "../../components/SessionScheduleContainer/badge";
import {
  closeAlert,
  createErrorAlert,
  deleteCsrf,
  deleteUser,
  getSiteSearch,
  getSpecificStudyModules,
  getUserInfo,
  initialError,
  getGrades,
  getCatalogue,
  updateUser,
} from "../../redux/actions";
import Dropdown from "../Dropdown";
import { connect, useDispatch, useSelector } from "react-redux";
import ErrorHandler from "../../components/Modal/ErrorHandler";
import Select from "../Select";
import {
  catalogueData,
  siteSearchData,
  siteSearchLoading,
  studyModulesData,
  userData,
} from "../../redux/selectors";
import { useHistory, withRouter } from "react-router-dom";
import search_icon from "../../assets/images/search.webp";
import {
  getUser,
  slugToText,
  removeCsrf,
  removeKey,
  removeUser,
} from "../../helpers";
import $ from "jquery";
import { Logout } from "../../services";
import ReactGA from "react-ga";
import AppConfig from "../../config/index";
import { compose } from "redux";
import FullStory from "react-fullstory";
import Button from "../EmptyButton";
import InfoBox from "../../components/InfoBox/SmallInfobox";
import InfoImg from "../../assets/icons/information.svg";
import Pusher from "pusher-js";
import NotificationDropDown from "../NotificationDropDown/NotificationDropDown";
import Flag from "react-flagkit";
import Context from "../../services/Context";
import { method } from "lodash";
import noApiRequests from "../../helpers/noApiRequests";

ReactGA.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE);
const ORG_ID = "S1P4H";

const AdminHeader = (props) => {
  const {
    user_detail,
    getSiteSearch,
    siteSearchData,
    loading,
    getUserInfo,
    getGrades,
    getCatalogue,
    dataCatalogue,
    studyModulesDataAPI,
    updateUser,
  } = props;

  const [module, setModule] = useState([]);
  const [user, setUser] = useState(JSON.parse(getUser()));
  const [studyModule, setStudyModule] = useState();
  const [searchChange, setSearchChange] = useState("Type to Search");
  const [calendarModal, setCalendarModal] = useState(false);
  const [studyModuleRes, setStudyModuleRes] = useState();
  const [timeSlots, setTimeSlots] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [date, setDate] = useState();
  const [time, setTime] = useState({ id: null, start: 0, end: 0 });
  const [showInfo, setShowInfo] = useState(false);
  const [trialExpiredInfo, setTrialExpiredInfo] = useState(false);
  const [showModalFree, setShowModalFree] = useState(false);
  const [maxDate, setMaxDate] = useState("");
  const [badgeContent, setBadgeContent] = useState(0);
  const [versionModal, setVersionModal] = useState(false);
  const [drop, setDrop] = useState(false);
  const [versions, setVersion] = useState("");
  const [loadingNotification, setLoadingNotification] = useState(false);
  const [notification, setNotification] = useState([]);
  const [gradeModal, setGradeModal] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [paidModules, setPaidModules] = useState([]);
  const [regions, setRegion] = useState([
    {
      value: "UK",
      label: (
        <div className="flex">
          <Flag country="GB" size={15} className="mr-2" /> UK
        </div>
      ),
    },
    {
      value: "US",
      label: (
        <div className="flex">
          <Flag country="US" size={15} className="mr-2" /> US
        </div>
      ),
    },
  ]);
  const { value, updateValue } = useContext(Context);
  const userCurrency = user?.user_currency || value?.userCurrency;
  const userIsLaw = value?.is_law || user?.is_law;
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [freeTrialModal, setFreeTrialModal] = useState(false);
  const popuped = localStorage.getItem("freeTrialPopuped");

  const [freeTrialSteps, setFreeTrialSteps] = useState([
    {
      title: "1. Notes:",
      content:
        "start with our greatest content! Simple, condensed summary of everything you need to know from textbooks, court judgments and journal articles in only about 10 pages per topic. With this, no need to read thousands of pages of complicated books anymore!",
    },
    {
      title: "2. Case Summaries:",
      content:
        "We offer simple, detailed case summaries with relevant pictures as a visual material to help you memorise them.",
    },
    {
      title: "3. Model Essays:",
      content:
        "First and 2.1 class essays to learn structuring problem/essay questions.",
    },
    {
      title: "4. Quiz & Flashcards:",
      content:
        "Designed specifically to practice your knowledge and help you memorise cases and legal principles.",
    },
    {
      title: "5. Tutorial Videos:",
      content:
        "Detailed, step by step guide as to answering exam questions. In particular, teaching how to correctly apply the law to the facts and give high level analysis.",
    },
    {
      title: "6. Exam Tips:",
      content:
        "Detailed, step by step guide as to the best exam strategy to adopt for maximising your chances of getting a First Class.",
    },
  ]);
  const [selectedGradeText, setSelectedGradeText] = useState("");
  const [gradesArray, setGradesArray] = useState([]);
  const [subscriptionTypes, setSubscriptionTypes] = useState([
    {
      label: "Monthly",
      price: "US $12",
      id: "monthly",
      name: "",
    },
    {
      label: "Quarterly",
      price: "US $13",
      id: "quarterly",
      name: "",
    },
    {
      label: "Yearly",
      price: "US $14",
      id: "yearly",
      name: "",
    },
  ]);
  const location = useLocation();
  let updates = false;
  let arr = [];

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getUserInfo();
  }, []);

  const userInfoRedux = useSelector((state) => state.store.userReducer);
  const errorAlert = useSelector(({ store }) => store.errorAlertReducer);
  const [selectedRegion, setSelectedRegion] = useState(null);

  useEffect(() => {
    getSpecificStudyModules();
    if (!noApiRequests().includes(location.pathname)) {
      getSubscriptionPrices();
    }
    if (user) {
      updateValue((prev) => ({
        ...prev,
        user: userInfoRedux?.user,
      }));
      setSelectedRegion(user?.user_currency);
      if (user?.user_currency === "UK") {
        setGradesArray([
          { id: 0, text: "Below 40", correct: false },
          { id: 1, text: "40-44", correct: false },
          { id: 2, text: "45-49", correct: false },
          { id: 3, text: "50-54 (Low 2.2)", correct: false },
          { id: 4, text: "55-59 (High 2.2)", correct: false },
          { id: 5, text: "60-64 (Low 2.1)", correct: false },
          { id: 6, text: "65-69 (High 2.1)", correct: false },
          { id: 7, text: "70+ (First Class)", correct: false },
        ]);
      } else if (user.user_currency === "US") {
        setGradesArray([
          { id: 0, text: "A", correct: false },
          { id: 1, text: "B", correct: false },
          { id: 2, text: "C", correct: false },
          { id: 3, text: "D", correct: false },
          { id: 4, text: "E", correct: false },
          { id: 5, text: "F", correct: false },
        ]);
      } else {
        setGradesArray([]);
      }
      if (
        userInfoRedux?.user?.user_currency === "UK" &&
        user?.is_law === true &&
        user?.subscription_name === "Basic Subscription" &&
        user?.free_trial === false &&
        user?.free_trial_end === false &&
        (popuped === false || !popuped) &&
        (location.pathname === "/" || location.pathname === "/profile")
      ) {
        setFreeTrialModal(true);
      }
    }
  }, [user]);

  useEffect(() => {
    // if(!value?.allModules){
    if (noApiRequests().includes(window.location.pathname)) {
      getAllStudyModules();
    }
    // }
  }, []);

  const getAllStudyModules = async () => {
    await apiRequest({
      method: "get",
      url: "studies/all/studymodules/",
    })
      .then((res) => {
        updateValue((prev) => ({
          ...prev,
          allModules: res.data,
        }));
      })
      .catch((err) => {
        console.log("unable to fetch");
      });
  };

  const logout = () => {
    Logout()
      .then(() => {
        removeCsrf();
        removeKey();
        removeUser();
        dispatch(deleteCsrf());
        dispatch(deleteUser());
        window.location.assign(`${window.location.origin}/login`);
        localStorage.clear();
      })
      .catch((error) => {
        localStorage.clear();
        window.location.assign(`${window.location.origin}/login`);
        // dispatch(createErrorAlert(2, "Error", "Try again", 2));
      });
  };
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const updateClick = () => {
    updates = true;
    setTimeout(() => {
      updateBuild();
    }, 2000);
  };
  const updateBuild = () => {
    if (updates === true) {
      localStorage.clear();
      localStorage.setItem("appVersion", versions);
      window.location.href = `${window.location.origin}/login`;
    } else {
      setVersionModal(false);
    }
  };

  const studyMods = async () => {
    let degree_id =
      typeof user?.degrees[0] === "object" && user?.degrees[0] !== null
        ? user?.degrees[0].id
        : user?.degrees[0];
    console.log(degree_id, "rrrr");
    if (user?.degrees?.length > 0) {
      await apiRequest({
        method: "get",
        url: `studies/studymodules/?degree_id=${degree_id}`,
      }).then((res) => {
        setStudyModuleRes(res.data);
        updateValue((prev) => ({
          ...prev,
          studyModulesData: res.data,
        }));
      });
    } else {
      await apiRequest({
        method: "get",
        url: `studies/studymodules/`,
      }).then((res) => {
        setStudyModuleRes(res.data);
        updateValue((prev) => ({
          ...prev,
          studyModulesData: res.data,
        }));
      });
    }
  };

  const today = new Date();

  today.setDate(today.getDate() + 3);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  useEffect(() => {
    if (!noApiRequests().includes(window.location.pathname)) {
      studyMods();
    }
  }, []);

  useEffect(() => {
    if (!value?.dataCatalogue || !value?.allModules) {
      getCatalogue(null);
    }
    if (window.location.href.includes("free_tutor")) {
      setConfirmModal(true);
    }

    Pusher.logToConsole = true;

    var pusher = new Pusher("d5b31dcf47110ce3665d", {
      cluster: "ap2",
    });

    const channel = pusher.subscribe("notification_channel");
    channel.bind("case_study_notification", (data) => {
      notification.push(data);
      if (drop === false) {
        setBadgeContent(notification.length);
      } else {
        setBadgeContent(0);
      }
    });
  }, [drop, user]);

  useEffect(() => {
    history.listen(() => {
      const user = JSON.parse(JSON.stringify(localStorage.getItem("app_key")));
      if (user) {
        const decodedJwt = parseJwt(user);
        if (decodedJwt.exp * 1000 < Date.now()) {
          logout();
          localStorage.clear();
          window.location.assign(`${window.location.origin}/login`);
        }
      }
    });
    const unlisten = props.history.listen((location, action) => {
      if (window.om118432_130548) {
        window.om118432_130548.reset();
      }
    });
    return unlisten;
  }, []);

  const confirmAppoint = async (status) => {
    let url_appoint = window.location.href.split("appointment=");
    let obj;
    if (status !== "") {
      obj = {
        status: status,
        appointment_id: parseInt(url_appoint[1]),
      };
    }

    await apiRequest({
      method: "post",
      url: "content/confirm/appointment/",
      data: obj,
    }).then((res) => {
      if (res?.data?.data.includes("Confirmed")) {
        setTimeout(() => {
          dispatch(
            createErrorAlert(
              201,
              "Success",
              "Congratulations! Your Free Appointment has been confirmed",
              201
            )
          );
        }, 2000);
        setConfirmModal(false);
        history.push("/");
      } else {
        setTimeout(() => {
          dispatch(
            createErrorAlert(
              201,
              "Success",
              "Your Free Appointment has been cancelled",
              201
            )
          );
        }, 2000);
        setConfirmModal(false);
        history.push("/");
      }
    });
  };

  useEffect(() => {
    if (!window.location.pathname.includes("/notes")) {
      localStorage.removeItem("pageData");
    }
  }, [location]);

  useEffect(() => {
    if (errorAlert?.showAlert) {
      document.body.style.overflowY = "hidden";
      document.body.style.position = "fixed";
    } else if (!errorAlert?.showAlert) {
      document.body.style.overflowY = "unset";
      document.body.style.position = "unset";
    }
  }, [errorAlert]);
  // useEffect(() => {
  //   setInterval(() => {
  //     if (getApiKey()) {
  //       fetch("/version.txt")
  //         .then((response) => response.text())
  //         .then((version) => {
  //           setVersion(version);
  //           const currentVersion = localStorage.getItem("appVersion");
  //           if (version !== currentVersion) {
  //             caches.keys().then((names) => {
  //               names.forEach((name) => {
  //                 caches.delete(name);
  //               });
  //             });
  //           }
  //         });
  //     }
  //   }, 10000);
  // }, []);
  useEffect(() => {
    let infoIcon = document.getElementById("freeIcon");
    if (infoIcon !== null) {
      if (trialExpiredInfo) {
        infoIcon.style.display = "none";
      } else {
        infoIcon.style.display = "block";
      }
    }
  }, [trialExpiredInfo]);
  useEffect(() => {
    if (siteSearchData) {
      if (siteSearchData.length > 0) {
        let modules = [];
        siteSearchData.map((item) => {
          if (item?.title?.includes("summary")) {
            return null;
          } else {
            modules.push({
              value: item,
              label: slugToText(item.title) + "   " + "- " + item.type,
            });
          }
        });
        setModule(modules);
      }
    }
    if (user_detail) {
      setUser(user_detail);
    }
  }, [siteSearchData, user_detail]);

  useEffect(() => {
    getUserInfo();

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    updateValue((prev) => ({
      ...prev,
      dataCatalogue: dataCatalogue?.data,
    }));
    for (let i = 0; i < dataCatalogue?.data?.length; i++) {
      if (dataCatalogue?.data[i]?.is_paid === true) {
        if (!paidModules.includes(dataCatalogue?.data[i]?.study_module?.slug)) {
          paidModules.push(dataCatalogue?.data[i]?.study_module?.slug);
        }
      }
    }
  }, [dataCatalogue]);

  useEffect(() => {
    let user = new Date(
      JSON.parse(sessionStorage.getItem("app_user"))?.grade_updated_at
    );
    if (
      user?.free_trial === true &&
      user?.subscription_name === "Basic Subscription" &&
      user?.grade === false
    ) {
      if (location.pathname === "/") {
        setGradeModal(true);
      }
    } else if (
      new Date() >= new Date(user.getTime() + 365 * 24 * 60 * 60 * 1000)
    ) {
      if (location.pathname === "/") {
        setGradeModal(true);
      }
    }
  }, [user]);

  const handleChangeGrades = (e, id) => {
    if (e.target.checked) {
      setSelectedGrade(id);
      setSelectedGradeText(e.target.value);
    }
  };

  const submitGrades = () => {
    getGrades(selectedGradeText);
    setGradeModal(false);
  };

  const getGradeAPI = async () => {
    await apiRequest({
      method: "get",
      url: "accounts/user/grades",
    }).then((res) => {
      getUserInfo();
    });
    let user = new Date(
      JSON.parse(sessionStorage.getItem("app_user"))?.grade_updated_at
    );
    if (new Date() === new Date(user.getDate() + 7)) {
      if (location.pathname === "/") {
        setGradeModal(true);
      }
    }
  };

  const handleCloseGradeModal = () => {
    setGradeModal(false);
    getGradeAPI();
  };

  const tutoring = () => {
    history.push("/boost-your-grades");
    $(".appendHeader").hide();
  };
  const studyArea = () => {
    history.push("/");
    $(".appendHeader").hide();
  };

  // const library = () => {
  //     history.push('/account/library');
  // };
  //
  // const joinTeam = () => {
  //     history.push('/account/content-creator');
  // };
  //
  // const basket = () => {
  //     history.push('/account/shopping-basket');
  // };

  const handleSelectChange = (change) => {
    if (change.length === 0) {
      setSearchChange("Type to Search");
      return; // Early return if no input
    }

    setSearchChange(
      "We cannot find what you are looking for. You can search by case names or legal principles e.g., consideration"
    );

    debouncedSearch(change);
  };

  const debouncedSearch = useCallback(
    debounce((change) => {
      dispatch(getSiteSearch(change));
    }, 500),
    []
  );

  const showCalendar = async (slug = "newly-created-package") => {
    updateValue((prev) => ({
      ...prev,
      package_slug: slug,
      selectedModule: {},
      claim: "free",
      selectedPackages: undefined,
    }));
    history.push({
      pathname: `/tutoring/tutor-selection/${slug}`,
    });
    // let date = new Date();

    // date.setDate(date.getDate() + 3);
    // let month = date.getMonth() + 1;
    // let selectedDate;
    // if (date.getMonth() + 1 < 10) {
    //   selectedDate =
    //     date.getFullYear() + "-" + 0 + month + "-" + date.getDate();
    // } else if (date.getDate() < 10) {
    //   selectedDate =
    //     date.getFullYear() + "-" + month + "-" + 0 + date.getDate();
    // } else if (date.getDate() < 10 && date.getMonth() + 1 < 10) {
    //   selectedDate =
    //     date.getFullYear() + "-" + 0 + month + "-" + 0 + date.getDate();
    // } else {
    //   selectedDate = date.getFullYear() + "-" + month + "-" + date.getDate();
    // }

    // setDate(selectedDate);
    // // Nini.Sarishvili

    // await apiRequest({
    //   method: "get",
    //   url: `accounts/tutor/Nini.Sarishvili/${selectedDate}`,
    // })
    //   .then((res) => {
    //     setTimeSlots(res.data);
    //     setCalendarModal(true);
    //   })
    //   .catch((err) => {
    //     if (
    //       err.response.data[0].includes(
    //         `Tutor has marked Holiday for this date: ${selectedDate}`
    //       )
    //     ) {
    //       setCalendarModal(true);
    //     }
    //   });
  };

  const handleClose = () => {
    setCalendarModal(false);
  };

  const getStudyMaterials = () => {
    history.push("/account/contentwelcome");
  };

  const handleSelectedModule = (selected) => {
    if (selected.value.type === "Note") {
      console.log('note1')

      setStudyModule(selected?.value?.study_module?.slug);
      if (selected.value.type === "StudyModule") {
        localStorage.setItem("study-module", selected.value.slug);
        localStorage.setItem("study-module_id", selected.value.id);
        history.push({
          pathname: `/study-module/${selected.value.slug}`,
          state: { selected },
        });
      } else if (selected.value.type === "Note") {
        console.log('note2')

        window.location.replace(
          `/${selected?.value?.study_module?.slug}/notes/${selected.value.slug}`
        );
      } else if (selected.value.type === "Essay") {
        console.log('essay')
        window.location.replace(
          `/${selected?.value?.study_module?.slug}/model-essays/${selected.value.slug}`
        );
      } else if (selected.value.type === "CaseStudy") {
        console.log('case')
        window.location.replace(`/${selected.value.slug}/`);
      } else if (selected.value.type === "TutorialVideo") {
        console.log('tutorial')

        window.location.href = selected.value.url;
      } else if (selected.value.type === "Quiz") {
        console.log('quiz')

        history.push({
          pathname: `/quiz/${selected.value.slug}/`,
          state: {
            state: selected.value.slug,
          },
        });
      } else if (selected.value.type === "FlashCard") {
        console.log('flash')

        history.push({
          pathname: `/${selected?.value?.study_module?.slug}/flashcards`,
        });
      }
    } else {
      setStudyModule(selected?.value?.study_module?.slug);
      if (selected.value.type === "StudyModule") {
        console.log('module')

        localStorage.setItem("study-module", selected.value.slug);
        localStorage.setItem("study-module_id", selected.value.id);
        history.push({
          pathname: `/study-module/${selected.value.slug}`,
          state: { selected },
        });
      } else if (selected.value.type === "Note") {
        console.log('note3')

        history.push({
          pathname: `/${selected?.value?.study_module?.slug}/notes/${selected.value.slug}`,
          state: { selected },
        });
        window.location.replace(
          `/${selected?.value?.study_module?.slug}/notes/${selected.value.slug}`
        );
      } else if (selected.value.type === "Essay") {
        console.log('esay2')

        history.push({
          pathname: `/${selected?.value?.study_module?.slug}/model-essays/${selected.value.slug}`,
          state: { selected },
        });
      } else if (selected.value.type === "CaseStudy") {
        console.log('case2')

        window.location.replace(`/${selected.value.slug}/`);
      } else if (selected.value.type === "TutorialVideo") {
        if (selected.value.author !== 1) {
          if (paidModules?.includes(selected?.value?.study_module?.slug)) {
            window.location.href = selected.value.url;
          } else {
            dispatch(
              createErrorAlert(
                211,
                "Info",
                "You have to buy this module to access this Tutorial Video",
                211
              )
            );
            setTimeout(() => {
              history.push("/");
            }, 4000);
          }
        } else {
          window.location.href = selected.value.url;
        }
      } else if (selected.value.type === "Quiz") {
        console.log('quiz2')

        history.push({
          pathname: `/quiz/${selected.value.slug}/`,
          state: {
            state: selected.value.slug,
          },
        });
      } else if (selected.value.type === "FlashCard") {
        console.log('flash2')

        if (selected.value.author !== 1) {
          if (paidModules?.includes(selected?.value?.study_module?.slug)) {
            history.push({
              pathname: `/${selected?.value?.study_module?.slug}/flashcards`,
            });
          } else {
            dispatch(
              createErrorAlert(
                211,
                "Info",
                "You have to buy this module to access this Flash Card",
                211
              )
            );
            setTimeout(() => {
              history.push("/");
            }, 4000);
          }
        } else {
          history.push({
            pathname: `/${selected?.value?.study_module?.slug}/flashcards`,
          });
        }
      }
    }
    setModule([]);
  };
  

  useEffect(() => {
    const unlisten = props.history.listen((location, action) => {
      if (window.om118432_130548) {
        window.om118432_130548.reset();
      }
    });
    return unlisten;
  }, []);

  useEffect(() => {
    history.listen(() => {
      const user = JSON.parse(JSON.stringify(localStorage.getItem("app_key")));
      if (user) {
        const decodedJwt = parseJwt(user);
        if (decodedJwt.exp * 1000 < Date.now()) {
          logout();
          localStorage.clear();
          window.location.assign(`${window.location.origin}/login`);
        }
      }
    });
    const unlisten = props.history.listen((location, action) => {
      if (window.om118432_130548) {
        window.om118432_130548.reset();
      }
    });
    return unlisten;
  }, []);

  useEffect(() => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let dates = year + "-" + month + "-" + day;
    setMaxDate(dates);
    $(document).ready(function () {
      $(".menuBar").click(function () {
        $(".appendHeader").show();
      });
      $(".closeIcon").click(function () {
        $(".appendHeader").hide();
      });
    });

    let icon = document.getElementById("freeIcon");
    let complete_icon = document.getElementById("complete-icon");
    if (showInfo === true || trialExpiredInfo === true) {
      if (icon) {
        icon.style.display = "none";
      }
      if (complete_icon) {
        complete_icon.style.display = "none";
      }
    } else {
      if (icon) {
        icon.style.display = "block";
      }
      if (complete_icon) {
        complete_icon.style.display = "block";
      }
    }
  });

  const timeSelect = async (time) => {
    let obj;
    for (let i = 0; i < timeSlots.length; i++) {
      timeSlots[i].available_slot.forEach((item) => {
        if (item.slot.slot === time.start) {
          obj = {
            availablity_id: timeSlots[i].availability_id,
            date: date,
            startTime: timeCheck(time.start),
          };
        }
      });
    }

    let objappoint = {
      user: user?.username,
      tutor: "testing",
      title: "Free Appointment",
      availability: [obj],
      packages: "one-2-one-package",
      date: "free_tutor",
      package_price: 40,
    };
    await apiRequest({
      method: "post",
      url: `content/create/tutor/appointment/`,
      data: objappoint,
    }).then((res) => {
      dispatch(
        createErrorAlert(
          201,
          "Success",
          "Your Free Appointment has been created with tutor",
          201
        )
      );
      user.free_tutor = true;
      localStorage.setItem("app_user", JSON.stringify(user));
      setCalendarModal(false);
      window.location.reload();
    });
  };

  const times = () => {
    console.log("");
  };

  const contactUs = () => {
    $(".appendHeader").hide();
    history.push("/contact-us");
  };

  const selectedTimeSlot = (selected) => {
    let obj = [...timeSlots];
    if (obj.length) {
      obj.map((subItem) => {
        subItem.available_slot.map((item) => {
          if (item.id === selected.id) {
            item.is_active = false;
          } else {
            item.is_active = true;
          }
        });
      });
    }
    setTimeSlots(obj);
    arr = [];
  };

  const trialExpired = () => {
    history.push("/subscription");
  };

  const handleRadioChange = (price, item) => {
    setSelectedPrice(price);
    setSelectedSubscription(item);
  };

  const handleFreeTrialContinue = async () => {
    localStorage.setItem("freeTrialPopuped", true);
    await apiRequest({
      method: "post",
      url: "subscriptions/free_trail/",
      data: {
        name: selectedSubscription.name,
        success_url: window.location.origin + "/",
        cancel_url: window.location.origin + "/",
      },
    })
      .then((res) => {
        window.location.href = res.data.sessionId;
      })
      .catch((err) => {
        dispatch(createErrorAlert(400, "", "Something went wrong", 400));
      });
  };

  const setUserAPI = async () => {
    setLoadingNotification(true);
    await apiRequest({
      method: "get",
      url: `accounts/read-notification`,
    }).then((res) => {
      getUserInfo();
      setLoadingNotification(false);
      setNotification(res.data.user?.notification);
      setBadgeContent(0);
    });
  };

  const handleOpenSideBar = async () => {
    setDrop(true);
    setBadgeContent(0);
    getUserInfo();
    setUserAPI();
    let sideBarDiv = document.getElementById("sideBarMen");
    if (sideBarDiv) {
      sideBarDiv.style.transition = "width 2s, height 2s, background-color 2s;";
      sideBarDiv.style.width = "0";
      sideBarDiv.style.height = "0";
      sideBarDiv.style.backgroundColor = "transparent";
      sideBarDiv.addEventListener(
        "transitionend",
        () => {
          setDrop(true);
        },
        { once: true }
      );
    }
  };

  const handleCloseSideBar = (value) => {
    let sideBarDiv = document.getElementById("sideBarMen");
    if (sideBarDiv) {
      sideBarDiv.style.transition = "width 2s, height 2s, background-color 2s;";
      sideBarDiv.style.width = "0";
      sideBarDiv.style.height = "0";
      sideBarDiv.style.backgroundColor = "transparent";
      sideBarDiv.addEventListener(
        "transitionend",
        () => {
          setDrop(value);
          setBadgeContent(0);
        },
        { once: true }
      );
    }
  };

  const dateSelected = async (date) => {
    setDate(date);
    await apiRequest({
      method: "get",
      url: `accounts/tutor/testing/${date}`,
    })
      .then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].available_slot.length === 0) {
            dispatch(createErrorAlert(400, "", "No Time Slot Available", 400));
            return;
          }
        }
        setTimeSlots(res.data);
        setShowModalFree(false);
        setCalendarModal(true);
      })
      .catch((err) => {
        if (
          err.response.data[0].includes(
            `Tutor has marked Holiday for this date: ${date}`
          )
        ) {
          dispatch(createErrorAlert(400, "", err.response.data[0], 400));
        }
      });
  };

  const handleSelectRegion = async (value) => {
    let obj = {
      username: user?.username,
      email: user?.email,
      user_currency: value.value,
    };
    setSelectedRegion(value.value);
    updateValue((prev) => ({
      ...prev,
      userCurrency: value.value,
    }));
    updateUser(obj);
  };

  const joinTeam = () => {
    $(".appendHeader").hide();
    history.push("/content-creator");
  };

  const basket = () => {
    $(".appendHeader").hide();
    history.push("/shopping-basket");
  };

  const library = () => {
    $(".appendHeader").hide();
    history.push("/library");
  };

  const getSubscriptionPrices = async () => {
    try {
      const res = await apiRequest({
        url: "subscriptions/subscriptions/",
        method: "get",
      });

      const subscriptions = res.data;

      const quickBoostSubscription = subscriptions.find(
        (sub) => sub.slug === "quick-boost-of-your-grades"
      );
      const getCoveredSubscription = subscriptions.find(
        (sub) => sub.slug === "get-covered-for-the-exam-period"
      );
      const idealIfSubscription = subscriptions.find(
        (sub) => sub.slug === "ideal-if-you-aim-for-a-first-class"
      );

      setSubscriptionTypes((prevState) => {
        return prevState.map((plan) => {
          if (plan.id === "monthly" && quickBoostSubscription) {
            return {
              ...plan,
              price:
                userInfoRedux?.user?.user_currency === "US"
                  ? `US $${quickBoostSubscription.membership_price}`
                  : `UK £${quickBoostSubscription.membership_price}`,
              name: quickBoostSubscription.name,
            };
          }
          if (plan.id === "quarterly" && getCoveredSubscription) {
            return {
              ...plan,
              price:
                userInfoRedux?.user?.user_currency === "US"
                  ? `US $${getCoveredSubscription.membership_price}`
                  : `UK £${getCoveredSubscription.membership_price}`,
              name: getCoveredSubscription.name,
            };
          }
          if (plan.id === "yearly" && idealIfSubscription) {
            return {
              ...plan,
              price:
                userInfoRedux?.user?.user_currency === "US"
                  ? `US $${idealIfSubscription.membership_price}`
                  : `UK £${idealIfSubscription.membership_price}`,
              name: idealIfSubscription.name,
            };
          }
          return plan;
        });
      });
    } catch (error) {
      console.error("Error fetching subscription prices", error);
    }
  };

  return (
    <div
      className=" bg-white
            min-h-64 px-2  lg:px-2 md:min-h-88 custom:px-16 custom2:px-26 xl:px-30 pb-2 md:pb-0"
      style={{
        boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
        paddingTop: "14px",
        position: "relative",
      }}
    >
      <FullStory org={ORG_ID} />
      <div
        className={`
            flex
            w-full
            md:justify-between
            justify-start
            items-center
           
          
            ${
              (history.location.pathname === "/content-creator/step-one" ||
                history.location.pathname === "/content-creator/step-two" ||
                history.location.pathname === "/content-creator/step-three" ||
                history.location.pathname === "/content-creator/study-module" ||
                history.location.pathname === "/content-creator/more-content" ||
                history.location.pathname === "/content-creator/my-portal" ||
                history.location.pathname ===
                  "/content-creator/my-dashboard") &&
              "hidden"
            }
        `}
      >
        <ErrorHandler
          isOpen={errorAlert?.showAlert}
          closeModal={(e) => dispatch(closeAlert(e))}
          simpleClose={() => dispatch(initialError())}
          title={errorAlert?.title}
          status={errorAlert?.status}
          content={errorAlert?.message}
        />
        {/* {user ? (
          <div className="w-16 md:w-20  xl:w-80 sideBarMain ml-3">
            <img
              src={Burger}
              alt="Burger"
              className="menuBar"
              style={{ cursor: "pointer" }}
            />
            <div className=" fixed h-screen top-0 left-0 md:top-0 md:left-0 z-auto	w-56  bg-white rounded-b-lg shadow-lg shadow-red-50 px-4 py-10 align-baseline custom:flex justify-between custom:ml-5  appendHeader">
              <div className="z-50 absolute -right-4 top-2 px-8 cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 closeIcon"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
              {user?.user_type === "STUDENT" ? (
                <div className="nav-item px-2.5 pr-0 my-1">
                <p                     className=" custom:text-14  font-bold mb-2"
                     onClick={library}
                    >Your Library</p>
                  <p
                    className=" custom:text-14 xl:text-h5 mt-4 font-bold"
                    style={{ cursor: "pointer" }}
                    onClick={contactUs}
                  >
                    Contact Us
                  </p>
                  <p
                  className=" custom:text-14 xl:text-h5 mt-4 font-bold"
                  onClick={joinTeam}
                >
                  Join Our Team
                </p>
                <p
                  className=" custom:text-14 xl:text-h5 mt-4 font-bold"
                  onClick={basket}
                >
                  Basket
                </p>
                </div>
              ) : user?.user_type === "TEACHER" ? (
                <div>
                  <p
                    className=" custom:text-14 xl:text-h5 font-bold"
                    onClick={studyArea}
                  >
                    Study Materials
                  </p>
                  <p
                    className=" custom:text-14 xl:text-h5 font-bold"
                    style={{ cursor: "pointer" }}
                    onClick={contactUs}
                  >
                    Contact Us
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        ) : null} */}

        <div
          className="mr-auto ml-16 sm:w-96 md:ml-0 md:mr-0"
          style={{ width: "160px" }}
          onClick={() => {
            history.push("/profile");
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            className="w-12 md:w-full md:px-4 logoImg w-20 sm:w-48 h-28"
            style={{ cursor: "pointer" }}
          />
        </div>
        {user ? (
          <>
            <div className="searchWrap adminSearchWrap md:w-80 sm:w-64 md:block hidden relative">
              <Select
                placeholder={
                  userInfoRedux?.user?.user_currency === "UK" &&
                  userIsLaw === true
                    ? "Search Cases, Topics, Legal Principles, Study Modules etc"
                    : "Search"
                }
                values={module}
                id="topSearch"
                handleSelected={handleSelectedModule}
                valueChange={handleSelectChange}
                noOptionMessage={
                  <div className="relative" style={{ overflow: "hidden" }}>
                    {searchChange}
                    <div className="bg-primary text-white text-center py-2 h-auto w-full mt-2 z-20">
                      <p>ASK THE AI TUTOR INSTEAD (COMING SOON!)</p>
                    </div>
                  </div>
                }
                loading={loading}
              />
              <div className="searchIcon" style={{ height: "50px" }}>
                <img src={search_icon} alt="search_icon" width="23" />
              </div>
            </div>
            <div className="  lg:flex justify-between align-center custom:ml-5 respHeader">
              <div className="nav-item lg-xl:block hidden">
                <p className="custom:text-14 xl:text-h5 font-bold">
                  Your Library
                </p>
              </div>
              <Select
                placeholder="Select Region"
                values={regions}
                value={{
                  label: (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {user?.user_currency === "UK" ? (
                        <>
                          <Flag country="GB" size={15} className="mr-2" />
                          <span>{userInfoRedux?.user?.user_currency}</span>
                        </>
                      ) : (
                        <>
                          <Flag country="US" size={15} className="mr-2" />
                          <span>{userInfoRedux?.user?.user_currency}</span>
                        </>
                      )}
                    </div>
                  ),
                  value: selectedRegion || userInfoRedux?.user?.user_currency,
                }}
                isClearable={false}
                handleSelected={handleSelectRegion}
              />
              {/* {user?.user_type === "STUDENT" ? (
                <div className="nav-item  md:mr-6 pr-0 start">
                  <p
                    className=" custom:text-14 xl:text-h5 font-bold"
                    onClick={tutoring}
                  >
                    Tutoring
                  </p>
                </div>
              ) : null}

              <div className="nav-item px-6 pr-0">
                <p
                  className=" custom:text-14 xl:text-h5 font-bold"
                  onClick={studyArea}
                >
                  Study Materials
                </p>
              </div> */}
              <div className="nav-item px-2.5 pr-0">
                <p
                  className=" custom:text-14 lg-xl:block hidden xl:text-h5 font-bold"
                  style={{ cursor: "pointer" }}
                  onClick={contactUs}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        {user?.free_trial === true &&
        user?.subscription_name === "Basic Subscription" &&
        user?.user_type === "STUDENT" ? (
          <div
            className=" custom2:block custom2:w-40 freeTrailBtn trail-width"
            style={{
              marginLeft: "0px",
              right: "0px",
              top: "20px",
            }}
          >
            <div className="FreeTrailBtnSec tab-set">
              <div className="toolTipMain ">
                <img
                  src={InfoImg}
                  alt="info-img"
                  className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                />
                {showInfo && (
                  <div className=" tooltipSSec  free-auto">
                    <InfoBox
                      text={
                        "<p>You are on a free trial that lasts for 3 days. With our premium membership, you gain unlimited access to expertly crafted, simplified study materials designed to help you ace your exams and achieve top grades. Cancel anytime, no strings attached. <br/></br/> Here’s what you get with our premium membership: <br/><br/> <ul><li><b>Simplified Notes:</b> Say goodbye to slogging through thousands of pages of complex textbooks. Our condensed notes distill the essentials, saving you 800 hours of reading per year while ensuring you grasp the key concepts quickly.</li><li><b>Practice Questions & Answers:</b> Get ahead with curated example questions and answers that mirror your exams, so you’ll know exactly what to expect and how to excel.</li><li><b>Tutorial Videos:</b> Enjoy mini-lectures that cut straight to the point. Our videos break down the most critical topics, showing you exactly how to secure those high grades.</li><li><b>Quizzes & Flashcards:</b> Test your knowledge and reinforce your learning with interactive quizzes and flashcards, designed to boost your confidence and your grades.</li></ul></p>"
                      }
                      align={"left"}
                    />
                  </div>
                )}
              </div>
              <div className="custom2:block">
                <Button
                  text="Free trial in progress"
                  filled
                  onClick={() => {
                    setShowInfo(true);
                  }}
                  onMouseLeave={() => {
                    setShowInfo(false);
                  }}
                />
              </div>
            </div>
          </div>
        ) : user?.subscription_name === "IDEAL IF YOU AIM FOR A FIRST CLASS" &&
          user?.user_type === "STUDENT" &&
          userInfoRedux?.user?.user_currency === "UK" ? (
          <div className="custom2:block" style={{}}>
            <Button
              fontSize={"12px"}
              onClick={() => {
                showCalendar();
              }}
            >
              <div className="flex pl-8 pr-8 space-x-4">
                <p>{user?.credit} Credit</p>
                <span className="mt-1">
                  <FaCalendar />
                </span>
              </div>
            </Button>
          </div>
        ) : user?.free_trial === false &&
          user?.subscription_name === "Basic Subscription" &&
          user?.case_study_subscription === null &&
          user?.user_type === "STUDENT" ? (
          <div
            className=" custom2:block custom2:w-40 freeTrailBtn trail-width"
            style={{
              marginLeft: "0px",
              right: "0px",
              top: "20px",
            }}
          >
            <div className="FreeTrailBtnSec tab-set">
              <div className="toolTipMain ">
                <img
                  src={InfoImg}
                  alt="info-img"
                  className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                  onMouseEnter={() => setTrialExpiredInfo(true)}
                  onMouseLeave={() => setTrialExpiredInfo(false)}
                />
                {trialExpiredInfo && (
                  <div className=" tooltipSSec">
                    <InfoBox
                      text={
                        "<p style='text-align:center'><b>Confused by too much reading and don't know where to start? No matter how hard you study you still get lower grades than you expect?</b></p><br/><b>StudyCrowd.AI can save your grades.</b> We've helped <b>15,000+ law students from most of the UK universities</b> improve their grades. We found that students have 90% higher chance of getting a First Class if they study regularly with our materials + 6 hours of tutoring.<br/><br/><b>Our study materials are...</b><br/><br/><ul><li><b>premium quality-</b> they are created by <b>top law graduates</b> who have recently got 1st Class or high 2.1 in their exams;</li><li><b>simple and concise-</b> on average, we save you <b>738 hours of reading</b> per year, that's <b>2213 pages less reading!</b></li><li><b>diverse-</b> we have 3,000 simplified case summaries, notes, flashcards, model essays and tutorial videos in 35+ study modules.</li></ul><br/><br/>90% of our loyal customers who regularly use our study materials and tutoring get a First Class. Many even get awards for being top of the class with the highest grades.<br/><a href='https://simplestudying.com/subscription'>Upgrade your subscription</a><br/><a href='https://www.reviews.co.uk/company-reviews/store/www.simplestudying.com?order=highest_rated'>See Customer Testinmonials</a>"
                      }
                    />
                  </div>
                )}
              </div>
              <div className="custom2:block" style={{}}>
                <Button
                  text="Trial Expired, Upgrade"
                  filled
                  fontSize={"12px"}
                  onClick={() => {
                    trialExpired();
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="bell-icon">
          <FaBell
            size={24}
            onClick={() => {
              handleOpenSideBar();
            }}
          />
          {(user?.new_content_added === true || badgeContent > 0) && (
            <span
              style={{
                position: "relative",
                top: "-28px",
                right: "-8px",
                background: "red",
                color: "white",
                borderRadius: "50%",
                width: "16px",
                height: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
              }}
            ></span>
          )}
        </div>
        {/* <Dropdown user={user} /> */}
        {drop && (
          <NotificationDropDown
            closeSide={handleCloseSideBar}
            loading={loadingNotification}
            notificationList={notification}
          />
        )}
        {/*{user?.user_type === 'STUDENT' && <div className="ml-16 md:ml-0 w-16 md:w-28">*/}
        {/*    <img src={Basket} alt="Cart" style={{cursor: 'pointer'}} onClick={basket}/>*/}
        {/*</div>}*/}
      </div>
      {history.location.pathname === "/content-creator/step-one" ||
      history.location.pathname === "/content-creator/step-two" ||
      history.location.pathname === "/content-creator/step-three" ||
      history.location.pathname === "/content-creator/study-module" ||
      history.location.pathname === "/content-creator/more-content" ||
      history.location.pathname === "/content-creator/my-portal" ||
      history.location.pathname === "/content-creator/my-dashboard" ? (
        <div className="relative">
          {/* <button onClick={() => {
              history.push('/')
            }} style={{float: 'right', border: '1px solid black', borderRadius: '0', padding: '10px'}} onMouseEnter={() => {
              setStudentInfo(true)
            }} onMouseOut={() => {
              setStudentInfo(false)
            }}>STUDENT</button>
            {studentInfo && <p style={{position:'absolute', top:'10px', right:'50px',zIndex:'9', border: '1px solid black', marginTop: '50px', borderRadius: '5px', padding: '10px', zIndex: '9999', backgroundColor: '#fff'}}>Get Back to Student Dashboard</p>} */}
        </div>
      ) : (
        ""
      )}
      <div className="searchWrap adminSearchWrap w-full md:hidden block secSearch mt-2">
        <Select
          placeholder="Search Cases, Topics, Legal Principles, Study Modules etc"
          values={module}
          id="topSearch"
          handleSelected={handleSelectedModule}
          valueChange={handleSelectChange}
          loading={loading}
        />
        <div className="searchIcon">
          <img src={search_icon} alt="search_icon" width="23" />
        </div>
      </div>
      <div>
        <Modal
          open={freeTrialModal}
          onClose={() => {
            setFreeTrialModal(false);
            localStorage.setItem("freeTrialPopuped", false);
          }}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class1",
          }}
        >
          <div className="w-full flex space-x-20">
            <div className="w-[50%]" style={{ width: "50%" }}>
              <div className="w-full">
                <h1 className="text-24 font-bold">
                  Try StudyCrowd.AI for Free
                </h1>
                <p>
                  Get everything you need to achieve top grades without reading
                  thousands of pages from overcomplicated textbooks <br />
                  The subcription includes simple and condensed study notes,
                  tutorial videos, quizzes, flashcards, example questions and
                  answers, and case summaries.
                </p>
                <p>Cancel anytime</p>
              </div>
              <div className="w-full">
                {subscriptionTypes.map((item, index) => {
                  return (
                    <div
                      className="checkbox flex items-center mt-2 md:mt-4"
                      key={index}
                    >
                      <div className="flex justify-between w-full items-center">
                        <div>
                          <input
                            id={item.id}
                            type="radio"
                            name="subscription"
                            className="input-assumpte"
                            onChange={() => handleRadioChange(item.price, item)}
                          />
                          <label
                            htmlFor={item.id}
                            className="text-16 custom2:w-10/12 md:text-body-large flex items-center"
                          >
                            {item.label}
                          </label>
                        </div>
                        <span className="ml-4">{item.price}</span>{" "}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex space-x-28" style={{ marginTop: "50px" }}>
                <p>Due {formattedDate}</p>
                <p>{selectedPrice || "0.00"}</p>
              </div>
              <div className="flex space-x-48" style={{ marginTop: "10px" }}>
                <p>
                  <b>Due Today</b>
                </p>
                <p>
                  <b>
                    {userInfoRedux?.user?.user_currency === "UK"
                      ? "UK £0"
                      : userInfoRedux?.user?.user_currency === "US"
                      ? "US $0"
                      : null}
                  </b>
                </p>
              </div>
              <div
                className="flex justify-center items-center w-48 ml-24"
                style={{ marginTop: "50px" }}
              >
                <Button
                  filled
                  text={"Continue"}
                  onClick={handleFreeTrialContinue}
                  className="w-24"
                />
              </div>
            </div>
            <div
              className="w-[50%] bg-lightgray"
              style={{ marginTop: "8%", width: "50%" }}
            >
              <ol className="relative border-l-2 border-red-500 dark:border-red-500 border-dashed space-y-16">
                <li className="mb-10 ms-6 relative">
                  <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary  rounded-full -left-6 ring-24 ring-white dark:ring-white dark:bg-white">
                    <FaCalendar className="text-primary" />
                  </span>
                  <h3 className="flex items-center mb-1 font-bold text-lg font-semibold ml-12 text-primary dark:text-primary">
                    Today
                  </h3>
                  <p className="block mb-2 ml-12 text-xs font-normal leading-none text-red dark:text-gray-500">
                    Your 3-day free trial starts now
                  </p>
                </li>
                <li className="mb-10 ms-6 relative">
                  <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary rounded-full -left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                    <FaBell className="text-primary" />
                  </span>
                  <h3 className="mb-1 text-lg text-primary font-bold ml-12 font-semibold text-gray-900 dark:text-white">
                    {formattedDate}
                  </h3>
                  <p className="block mb-2 ml-12 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    Your subscription starts, unless you've cancelled during the
                    trial
                  </p>
                </li>
                <li className="ms-6 relative">
                  <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary rounded-full -left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                    <FaCreditCard className="text-primary" />
                  </span>
                  <h3 className="mb-1 text-lg text-white font-bold ml-12 font-semibold text-gray-900 dark:text-white">
                    October 3rd, 2024
                  </h3>
                  <p className="block mb-2 ml-12 text-sm font-normal leading-none text-white dark:text-white">
                    Your subscription starts, unless you've cancelled during the
                    trial
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </Modal>
        <Modal
          open={calendarModal}
          onClose={handleClose}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class2",
          }}
        >
          <div>
            {timeSlots?.length > 0 ? (
              <p className="text-darkest text-large md:text-h5 font-bold mb-5">
                Choose Time Slots for the date {date}
              </p>
            ) : (
              <p className="text-darkest text-large md:text-h5 font-bold mb-5">
                No Time Slot Available for three days ahead you can check any
                next availability
              </p>
            )}

            <div className="badgeMain">
              {timeSlots?.length > 0
                ? timeSlots?.map((item) =>
                    item?.available_slot?.map((subItem, index) => {
                      if (!arr.includes(subItem.slot.slot)) {
                        arr.push(subItem.slot.slot);
                        arr = arr.filter((item, index) => {
                          return arr.indexOf(item) === index;
                        });
                        return (
                          <div className="badgeBox" key={index}>
                            <Badge
                              setTime={setTime}
                              startTime={subItem.slot.slot}
                              onClick={selectedTimeSlot}
                              item={subItem}
                              id={subItem.id}
                              dates={times}
                              dateArray={[]}
                              selected={subItem.is_active}
                              endTime={subItem?.end_time}
                            />
                          </div>
                        );
                      }
                    })
                  )
                : null}
            </div>
            <Button
              filled
              onClick={() => {
                setShowModalFree(true);
              }}
            >
              <p className="text-center text-14 xl:text-body-large">
                Next Availability
              </p>
            </Button>
            <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
              <div className="w-56">
                <Button simple>
                  <p className="text-center text-14 xl:text-body-large">
                    Cancel
                  </p>
                </Button>
              </div>
              <div className="w-56 mt-5 md:mt-0">
                <Button
                  filled
                  onClick={() => {
                    timeSelect(time);
                  }}
                >
                  <p className="text-center text-14 xl:text-body-large">
                    Confirm
                  </p>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={confirmModal}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class2",
          }}
        >
          <div>
            <p className="text-darkest md:text-h3 font-bold mb-5">
              Please CONFIRM OR CANCEL your tutoring session at StudyCrowd.AI
            </p>
            <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
              <div className="w-56">
                <Button
                  simple
                  onClick={() => {
                    confirmAppoint("CANCEL");
                  }}
                >
                  <p className="text-center text-14 xl:text-body-large">
                    Cancel
                  </p>
                </Button>
              </div>
              <div className="w-56 mt-5 md:mt-0">
                <Button
                  filled
                  onClick={() => {
                    confirmAppoint("CONFIRM");
                  }}
                >
                  <p className="text-center text-14 xl:text-body-large">
                    Confirm
                  </p>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={showModalFree}
          onClose={() => setShowModalFree(false)}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class2",
          }}
        >
          <div>
            <p className="text-darkest md:text-h3 font-bold mb-5">
              Select the Date
            </p>
            <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
              <div className="w-full">
                <input
                  className="w-full border rounded p-2"
                  type="date"
                  min={maxDate}
                  onChange={(e) => {
                    setCalendarModal(true);
                    dateSelected(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={gradeModal}
          onClose={handleCloseGradeModal}
          focusTrapped={false}
          center
        >
          <div>
            <p style={{ fontSize: "20px" }}>
              Hey there, for our data analysis purposes, we'd like to ask you
              what is your average grade right now?{" "}
            </p>
            {gradesArray.map((item, index) => {
              return (
                <div key={index}>
                  <div className="checkbox flex items-center  mt-4">
                    <input
                      type={"checkbox"}
                      className="checkBoxGrades"
                      onChange={(e) => {
                        handleChangeGrades(e, item.id);
                      }}
                      id={item.id}
                      value={item.text}
                      checked={selectedGrade === item.id}
                    />
                    <label
                      htmlFor={item.text}
                      className="text-16 custom2:w-10/12 md:text-body-large text-darkest flex items-center"
                    >
                      {item.text}
                    </label>
                  </div>
                </div>
              );
            })}
            <div className="mt-4">
              <Button text="Submit" onClick={submitGrades}></Button>
            </div>
            <p>
              Note: This will be kept confidential. We will compare it to the
              grade you get after a year of using StudyCrowd.AI to assess the
              effectiveness of our platform and improve our services to maximise
              your chances of getting a{" "}
              {userInfoRedux?.user?.user_currency === "UK"
                ? "First Class degree"
                : "High Grades"}
              .
            </p>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_detail: userData(state),
  siteSearchData: siteSearchData(state),
  loading: siteSearchLoading(state),
  studyModulesDataAPI: studyModulesData(state),
  dataCatalogue: catalogueData(state),
});

const mapDispatchToProps = (dispatch) => ({
  // getUserCSRF: () => dispatch(getUserCSRF()),
  getUserInfo: () => dispatch(getUserInfo()),
  getSpecificStudyModules: (degree) =>
    dispatch(getSpecificStudyModules(degree)),
  getSiteSearch: (text) => dispatch(getSiteSearch(text)),
  getGrades: (selectedGradeText) => dispatch(getGrades(selectedGradeText)),
  getCatalogue: (id) => dispatch(getCatalogue(id)),
  updateUser: (body) => dispatch(updateUser(body)),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AdminHeader);
