import React, { useState, useEffect, useContext } from "react";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import {
  FaShoppingCart,
  FaEnvelope,
  FaBookOpen,
  FaUsers,
  FaRobot,
  FaSignOutAlt,
  FaChalkboardTeacher,
  FaSearch,
} from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { MdSpaceDashboard } from "react-icons/md";
import DefaultDp from "../../assets/images/defaultDp.webp";
import {
  getApiKey,
  getUser,
  removeCsrf,
  removeKey,
  removeUser,
} from "../../helpers";
import { useHistory, useLocation } from "react-router-dom";
import { Logout } from "../../services";
import { connect, useDispatch } from "react-redux";
import {
  createErrorAlert,
  deleteCsrf,
  deleteUser,
  getCatalogue,
} from "../../redux/actions";
import { catalogueData } from "../../redux/selectors";
import NavItemTooltip from "../InfoBox/NavItemTooltip.js";
import Context from "../../services/Context.js";
const SideNavAI = ({
  marginState,
  setMarginState,
  getCatalogue,
  dataCatalogue,
}) => {
  const [paidModules, setPaidModules] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { value } = useContext(Context);
  const user = JSON.parse(getUser());

  const userCurrency = value?.userCurrency || user?.user_currency;
  const userIsLaw = value?.is_law || user?.is_law;

  useEffect(() => {
    for (let i = 0; i < value?.dataCatalogue?.length; i++) {
      if (value?.dataCatalogue[i]?.is_paid === true) {
        if (
          !paidModules.includes(value?.dataCatalogue[i]?.study_module?.slug)
        ) {
          paidModules.push(value?.dataCatalogue[i]?.study_module?.slug);
        }
      }
    }
  }, [value?.dataCatalogue]);

  const logout = () => {
    Logout()
      .then(() => {
        removeCsrf();
        removeKey();
        removeUser();
        dispatch(deleteCsrf());
        dispatch(deleteUser());
      })
      .catch((error) => dispatch(createErrorAlert(2, "Error", "Try again", 2)));
    localStorage.clear();
    sessionStorage.clear();
    window.location.assign(`${window.location.origin}/login`);
  };

  const handleSelectedItem = (selected) => {
    // if (selected === "join") {
    //   history.push("/content-creator");
    // } else if (selected === "library") {
    //   history.push("/library");
    // } else if (selected === "contact") {
    //   history.push("/contact-us");
    // } else if (selected === "basket") {
    //   history.push("/shopping-basket");
    // } else if (selected === "profile") {
    //   history.push("/profile");
    // } else if (selected === "logout") {
    //   logout();
    // } else if (selected === "dashboard") {
    //   history.push("/content-creator/my-dashboard");
    // } else if (selected === "tutoring") {
    //   history.push("/boost-your-grades");
    // } else if (selected === "study") {
    //   history.push("/");
    // } else if (selected === "ai") {
    //   history.push("/aitutor");
    // }
  };

  const handleToggle = (value) => {
    setMarginState(value);
  };

  const handleErrorImage = (e) => {
    e.target.src = DefaultDp;
  };

  return (
    <div
      className="w-16 md:w-20  xl:w-80 sideBarMain ml-3"
      style={{ zIndex: "99999999" }}
    >
      <SideNav
        onSelect={(selected) => {
          handleSelectedItem(selected);
        }}
        onToggle={handleToggle}
        className="sidenavStyle"
        style={{ zIndex: "99999999" }}
      >
        <SideNav.Toggle />
        <SideNav.Nav
          style={{ display: "flex", flexDirection: "column" }}
          className="logoutItem"
        >
          <NavItem eventKey="dashboard" className="navItem">
            <NavIcon>
              <FaRobot style={{ fontSize: "1.75em", margin: "13px 20px" }} />
            </NavIcon>
            <NavText>AI Tutor</NavText>
          </NavItem>
          <NavItem eventKey="join" className="navItem">
            <NavIcon>
              <FaSearch style={{ fontSize: "1.75em", margin: "13px 20px" }} />
            </NavIcon>
            <NavText><input type="text" placeholder="Search Chat" className="placeholder-white pl-2 focus:outline-none focus:ring focus:ring-transparent bg-transparent h-8 text-white" style={{width: '170px'}}/></NavText>
          </NavItem>
          {marginState && <><NavItem eventKey="ai" className="navItem text-center mt-16 font-bold">
            <NavText className="text-center">Chat History</NavText>
          </NavItem>
          <NavItem eventKey="dashboard" className="navItem">
            <NavText><div>
                <p>asdas</p>
                <p>sdajk</p>
                </div></NavText>
          </NavItem>
          <NavItem eventKey="dashboard" className="navItem">
            <NavIcon>
              <FaRobot style={{ fontSize: "1.75em", margin: "13px 20px" }} />
            </NavIcon>
            <NavText>AI Tutor</NavText>
          </NavItem>
          <NavItem eventKey="dashboard" className="navItem">
            <NavIcon>
              <FaRobot style={{ fontSize: "1.75em", margin: "13px 20px" }} />
            </NavIcon>
            <NavText>AI Tutor</NavText>
          </NavItem></>}
        </SideNav.Nav>
      </SideNav>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dataCatalogue: catalogueData(state),
});
const mapDispatchToProps = (dispatch) => ({
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNavAI);
