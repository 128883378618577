import { openDB } from 'idb';

const DB_NAME = 'myDatabase';
const STORE_NAME = 'myStore';

export async function initDB() {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    },
  });
}

export async function setItem(key, value) {
  const db = await initDB();
  return db.put(STORE_NAME, value, key);
}

export async function getItem(key) {
  const db = await initDB();
  return db.get(STORE_NAME, key);
}

export async function deleteItem(key) {
  const db = await initDB();
  return db.delete(STORE_NAME, key);
}

export async function clearDB() {
  const db = await initDB();
  return db.clear(STORE_NAME);
}
