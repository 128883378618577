import React, { useEffect, useState } from 'react';
import Context from './Context';
import { getItem, setItem } from './indexedDB';

const ContextProvider = ({ children }) => {
    const [value, setValue] = useState(null);

    useEffect(() => {
        async function loadData() {
            const storedValue = await getItem('myContextValue');
            if (storedValue) setValue(storedValue);
        }
        loadData();
    }, []);

    useEffect(() => {
        if (value !== null) {
            setItem('myContextValue', value);
        }
    }, [value]);

    const updateValue = (newValue) => {
        setValue(newValue);
    };

    return (
        <Context.Provider value={{ value, updateValue }}>
            {children}
        </Context.Provider>
    );
};

export default ContextProvider;
