import React from "react";
import Container from "../../components/container";

const AITutor = () => {
  return (
    <div className="bg-white mt-24 bg-main-admin-back bg-no-repeat">
      <Container>
        <div className="flex flex-col md:flex-row mb-5">
            dsajlk
        </div>
      </Container>
    </div>
  );
};

export default AITutor;
