import React, { useState, useEffect } from "react";
import "./index.css";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/container";
import timeCheck from "../../helpers/timecheck";
import editIcon from "../../assets/images/editIcon.webp";
import FullLoader from "../../components/Loader/FullLoader";
import apiRequest from "../../helpers/apiRequest";
import {
  groupParticipantsData,
  tutorSessionUpdateData,
  groupParticipantsLoading,
  tutorSessionUpdateLoading,
  checkTimeSlotData,
} from "../../redux/selectors";
import {
  checkTimeAction,
  getGroupParticipants,
  tutorSessionUpdate,
} from "../../redux/actions";
import { Modal } from "react-responsive-modal";
import SingleCheckbox from "../../components/CheckBoxQuestion/SingleCheckBox";
import Button from "../../components/EmptyButton";
import { Helmet } from "react-helmet";
import { GetTimeSlots } from "../../services";
import Input from "../../components/Input/InputForm";
import { useHistory } from "react-router";
import { createErrorAlert } from "../../redux/actions";
import { getUser } from "../../helpers";
import { useLocation } from "react-router-dom";
import { data } from "jquery";

const SessionDetail = (props) => {

  const {
    participants,
    getGroupParticipants,
    updateSession,
    groupParticipantsLoading,
    tutorSessionUpdateLoading,
    checkTimeSlotAction,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation()

  const {data, oneToOne} = location.state

  const [state, setState] = useState({
    modal: false,
    dateModal: false,
    date: "",
    time: "",
    timeArray: [],
    timeError: false,
    sessionStatuses: [
      { status: "CANCEL", correct: false },
      {
        status: "RESCHEDULE",
        correct: false,
      },
      { status: "NOTIFY", correct: false },
    ],
    selectedState: "",
    user: JSON.parse(getUser())
  });

  let count = 1;

  useEffect(() => {
    if (props.location?.state?.data) {
      getGroupParticipants(data.slug, data.date);
    } else {
      getGroupParticipants(state.user?.username, oneToOne.date);
    }
    GetTimeSlots().then((response) => {
      let obj = [];
      if (response?.data?.sunday?.length !== 0) {
        response?.data?.sunday?.map((item) => {
          obj.push(item?.slot?.slot);
        });
      }
      setState((prevState) => ({
        ...prevState,
        timeArray: obj
      }))
    });
  }, []);

  const sessionCheck = (value) => {
    if (value === "RESCHEDULE") {
      setState((prevState) => ({
        ...prevState,
        dateModal: true,
      }));
    }

    let data = [...state.sessionStatuses];
    data.map((item) => {
      if (item.status === value) {
        item.correct = true;
      } else {
        item.correct = false;
      }
    });
    setState((prevState) => ({
        ...prevState,
        sessionStatuses: data,
        selectedState: value
    }))
  };

  const holidayCheck = async () => {
    checkTimeSlotAction(state.user?.username, state.date).then((res) => {
      if (
        res.response.data[0] ===
          `Tutor has marked Holiday for this date: ${state.date}` &&
        state.user?.user_type === "STUDENT"
      ) {
        dispatch(
          createErrorAlert(
            400,
            "Error",
            "Tutor is not available on your selected date",
            400
          )
        );
      } else if (
        res.response.data[0] ===
          `Tutor has marked Holiday for this date: ${state.date}` &&
        state.user?.user_type === "TEACHER"
      ) {
        dispatch(
          createErrorAlert(
            400,
            "Error",
            "You have marked this selected date as holiday",
            400
          )
        );
      }
      return true;
    });
  };

  const handleSession = async () => {
    const resp = await holidayCheck();
    if (resp === true) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      modal: false,
    }));
    if (state.date && state.time) {
      let obj = {
        title: data.title,
        date: state.date,
        start_time: timeCheck(state.time),
        status: state.selectedState,
      };
      updateSession(data.slug, obj);

      getGroupParticipants(data.slug, data.date);
      history.push("/sessions");
      window.location.reload();
    } else {
      if (state.time === "") {
        setState((prevState) => ({
            ...prevState,
            timeError: true
        }))
        return;
      } else {
        setState((prevState) => ({
            ...prevState,
            timeError: false
        }))
      }
      let obj = {
        title: data.title,
        date: data.date,
        start_time: data.start_time,
        end_time: data.end_time,
        status: state.selectedState,
      };
      setState((prevState) => ({
        ...prevState,
        dateModal: false,
      }));
      updateSession(data.slug, obj);

      getGroupParticipants(data.slug, data.date);
      // history.push('/sessions')
      // window.location.reload()
    }
  };
  return (
    <>
      {groupParticipantsLoading || tutorSessionUpdateLoading ? (
        <FullLoader />
      ) : (
        <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat custom2:pb-20">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Simple Studying | Sessions Detail</title>
            <meta name="description" content="Get High Grades with the support of top graduates who took the same courses as you. Save time and Study more efficiently. We have simple study resources created by top students and graduates." />
            <link
              rel="canonical"
              href="https://simplestudying.com/session-detail"
            />
            <meta name="robots" content="index, follow" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:locale:alternate" content="en_gb" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Simple Studying | Sessions Detail"
            />
            <meta
              property="og:description"
              content="Get High Grades with the support of top graduates who took the same courses as you"
            />
            <meta
              property="og:url"
              content="https://simplestudying.com/session-detail"
            />
            <meta property="og:site_name" content="StudyCrowd.AI" />
          </Helmet>
          <Modal
            open={state.modal}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                modal: false,
              }))
            }
            focusTrapped={false}
            center
            classNames={{
              modal: "react-modal-class2",
            }}
          >
            <div class="radioRow flex justify-around my-12">
              {state.sessionStatuses.map((item) => (
                <SingleCheckbox
                  id={item.status}
                  text={item.status}
                  correct={item.correct}
                  handleChange={sessionCheck}
                />
              ))}
            </div>

            <div className="mt-6 flex flex-col md:flex-row items-center">
              <div className="w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end">
                <div className="md:w-44">
                  <Button filled onClick={handleSession}>
                    <p className="text-15 mac:text-body-large">Confirm</p>
                  </Button>
                </div>

                {/*<div className="mt-6 flex flex-col md:flex-row items-center">*/}
                {/*    <div*/}
                {/*        className="w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end">*/}
                {/*        <div className="md:w-44">*/}
                {/*            <Button filled onClick={() => setModal(false)}*/}
                {/*            >*/}
                {/*                <p className="text-15 mac:text-body-large">Confirm</p>*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </Modal>
          <Modal
            open={state.dateModal}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                dateModal: false,
              }))
            }
            focusTrapped={false}
            center
            classNames={{
              modal: "react-modal-class2",
            }}
          >
            <div class="radioRow flex justify-around my-12">
              <div className="w-3/6">
                <label className="mb-8 md:text-body-large">Calendar</label>
                <Input
                  type="date"
                  value={state.date}
                  setValue={(value) => {
                    setState((prevState) => ({
                      ...prevState,
                      date: value,
                    }));
                  }}
                />
              </div>
              <div className="w-3/6">
                <label className="mb-8 md:text-body-large">Time</label>
                <div className="flex justify-center">
                  <select
                    className="form-select text-input focus:bg-offWhite focus:border-none text-input-container w-fullpx-3 py-1.5 text-base font-normal text-gray-700 transitionease-in-out m-0
     "
                    aria-label="Default select example"
                    onChange={(e) => setState((prevState) => ({
                        ...prevState,
                        time: e.target.value
                    }))}
                    required={true}
                  >
                    <option value="select">--- Select any Time Slot ---</option>
                    {state.timeArray?.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </select>
                </div>
                {state.timeError && (
                  <p style={{ color: "red" }}>*Time field is required</p>
                )}
              </div>
            </div>

            <div className="mt-6 flex flex-col md:flex-row items-center">
              <div className="w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end">
                <div className="md:w-44">
                  <Button filled onClick={handleSession}>
                    <p className="text-15 mac:text-body-large">Confirm</p>
                  </Button>
                </div>

                {/*<div className="mt-6 flex flex-col md:flex-row items-center">*/}
                {/*    <div*/}
                {/*        className="w-full flex flex-col space-y-5 md:flex-row md:items-center md:space-y-0 md:space-x-5 md:justify-end">*/}
                {/*        <div className="md:w-44">*/}
                {/*            <Button filled onClick={() => setModal(false)}*/}
                {/*            >*/}
                {/*                <p className="text-15 mac:text-body-large">Confirm</p>*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </Modal>
          <div className="">
            <Container>
              <div className="pt-10 lg:pt-14 flex mb-10">
                <div className="text-darkest flex justify-between w-full">
                  <div>
                    <p className="text-h3  font-bold mb-10 custom2:mb-8 ">
                      Participants List
                    </p>
                  </div>
                </div>
              </div>
              <div className=" inline-block min-w-full sm:px-6 lg:px-8 w-full">
                <div className="overflow-y-hidden	sm:overflow-x-scroll lg:overflow-x-hidden">
                  {/* for group */}
                  {/* <table className="min-w-full text-center w-full">
                <thead className="border-b border-t bg-white text-white bg-primary borderClr">
                  <tr>
                    <th scope="col" className="text-sm font-medium  px-6 py-4">
                      ID
                    </th>
                    <th scope="col" className="text-sm font-medium  px-6 py-4">
                      Name
                    </th>
                    <th scope="col" className="text-sm font-medium  px-6 py-4">
                      Email
                    </th>
                    <th scope="col" className="text-sm font-medium  px-6 py-4">
                      Contact
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b borderClr	">
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      1
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      maryam
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      mariamkiran@gmail.com
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      0456-7890897
                    </td>
                  </tr>
                </tbody>
              </table> */}
                  {/* for one to one */}
                  <table className="min-w-full text-center w-full">
                    <thead className="border-b border-t bg-white text-white bg-primary borderClr">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-4"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-4"
                        >
                          Student Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-4"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-4"
                        >
                          Contact
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-4"
                        >
                          Start Time
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-4"
                        >
                          Payment status
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-4"
                        >
                          Session status
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium  px-6 py-4"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    {participants?.length
                      ? participants.map((item) => {
                          return (
                            <tbody>
                              <tr className="bg-white border-b borderClr	">
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {count++}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {item.participant
                                    ? item?.participant?.username
                                    : item?.user?.username}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {item?.participant
                                    ? item?.participant?.email
                                    : item?.user?.email}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {item?.participant
                                    ? item?.participant?.contact_number
                                    : item?.user?.contact_number}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {data.start_time}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  paid
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {data.status}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                  {/* <p onClick={() => setModal(true)}
                                                           className="inline-block text-13 md:text-16 text-right text-primary font-bold">
                                                            <img className="inline-block cursor-pointer"
                                                                 src={editIcon} alt="edit"/>
                                                        </p> */}
                                  <Button
                                    text={"Reschedule"}
                                    onClick={() => sessionCheck("RESCHEDULE")}
                                  ></Button>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })
                      : "No Participants"}
                  </table>
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  participants: groupParticipantsData(state),
  update: tutorSessionUpdateData(state),
  tutorSessionUpdateLoading: tutorSessionUpdateLoading(state),
  groupParticipantsLoading: groupParticipantsLoading(state),
  checkTimeData: checkTimeSlotData(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSession: (body, slug) => dispatch(tutorSessionUpdate(body, slug)),
  getGroupParticipants: (slug, date) =>
    dispatch(getGroupParticipants(slug, date)),
  checkTimeSlotAction: (slug, date) => dispatch(checkTimeAction(slug, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionDetail);
