import React, { useContext, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Context from "../../services/Context";
import { useLocation, useHistory, Link } from "react-router-dom";
import { getApiKey, getUser } from "../../helpers";
import Button from "../../components/EmptyButton";
import Input from "../../components/Input/InputComponent";
import { Field, Form, Formik } from "formik";
import img1 from "../../assets/newImages/Group 1399.png";
import img3 from "../../assets/newImages/Group 1403.png";
import img2 from "../../assets/newImages/Illustration.png";
import Modal from "react-responsive-modal";
import * as Yup from "yup";
import Logos from "../../assets/images/study_small.png";
import {
  closeAlert,
  createErrorAlert,
  initialError,
  userLogin,
  userSignUp,
} from "../../redux/actions";
import getCatalogue from "../../services/Shop/getCatalogue";
import { catalogueData } from "../../redux/selectors";
import GoogleAuthentication from "../../components/SocialAuthentication/GoogleSocialAuth";
import ErrorHandler from "../Modal/ErrorHandler";

const NoticeUs = (props) => {
  const { userSignUp, userLogin } = props;

  const location = useLocation();
  const user = JSON.parse(getUser());
  const history = useHistory();
  const dispatch = useDispatch();
  const [paidModules, setPaidModules] = useState([]);
  const [state, setState] = useState({
    loginModal: false,
    signUpModal: false,
    showPassword: false,
    checkbox: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const paidModulesState = useSelector(
    (state) => state.store.catalogueReducer?.data?.data
  );
  let module = location.pathname.includes("/notes/")
    ? location.pathname.split("/")[1]
    : localStorage.getItem("studyModule");

  const { value, updateValue } = useContext(Context);
  const userCurrency = value?.userCurrency || user?.user_currency;
  const userIsLaw = value?.is_law || user?.is_law;

  const validateSchemaSignup = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string()
      .required("Username is required")
      .min(5, "Minimum 5 characters")
      .max(26, "Maximum 26 characters"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Passwords must match"
    ),
    password1: Yup.string()
      .required("Password is required.")
      .min(7, "Password is too short - should be 6+ chars minimum.")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password should have at least one capital letter & one digit."
      ),
  });

  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleGetCatalogue = () => {
    if (paidModulesState?.length > 0) {
      let arr = [];
      for (let i = 0; i < paidModulesState?.length; i++) {
        if (paidModulesState[i]?.is_paid === true) {
          if (!arr.includes(paidModulesState[i]?.study_module?.slug)) {
            arr.push(paidModulesState[i]?.study_module?.slug);
          }
        }
      }
      setPaidModules(arr);
    }
  };

  useEffect(() => {
    handleGetCatalogue();
  }, [paidModulesState]);
  useEffect(() => {}, [location.pathname, module]);

  const onSignUp = async (values) => {
    setState((prev) => ({
      ...prev,
      loader: true,
    }));
    if (state.checkBox === true) {
      userSignUp(values, history).then((res) => {
        if (res !== undefined) {
          dispatch(createErrorAlert(400, "", res, 400));
        }
      });
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Please agree to the terms and Conditions",
          201
        )
      );
    }
  };

  const handleUserLogin = async (body) => {
    setState((prev) => ({
      ...prev,
      loader: true,
    }));
    userLogin(body, history);
  };

  const checked = (e) => {
    if (e === true) {
      setState((prev) => ({
        ...prev,
        checkbox: true,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        checkbox: false,
      }));
    }
  };

  return (
    <>
      {(!paidModules.includes(module) &&
        userCurrency === "UK" &&
        userIsLaw === true) ||
        (!getApiKey() && !window.location.pathname.includes('aitutor') && (
          <div className="border border-0.5 p-4" style={{backgroundColor: '#ffebfb', borderColor: '#ff59de'}}>
            <p className="font-bold mb-4">Important Notice:</p>
            <p className="mb-2">
              Our study materials are created by top students and graduates who
              have excelled in the same courses you're taking. These expertly
              crafted notes are designed to save you countless hours of studying
              while supercharging your grades.
            </p>
            <p className="mb-2">
              Why sift through thousands of pages of complicated textbooks when
              you can rely on our concise, straightforward, and laser-focused
              study notes to achieve your academic goals?
            </p>
            <p className="mb-2">
              <span
                className="underline cursor-pointer"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    signUpModal: true,
                  }));
                }}
              >
                Sign up
              </span>{" "}
              or{" "}
              <span
                className="underline cursor-pointer"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    loginModal: true,
                  }));
                }}
              >
                Login
              </span>{" "}
              today to unlock access and start improving your grades now!
            </p>
          </div>
        ))}

      {/* Sign up Modal */}

      <Modal
        open={state.signUpModal}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            signUpModal: false,
          }))
        }
        focusTrapped={false}
        center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "react-modal-class1",
        }}
      >
        <div className="flex gap-6">
          <div className="  flex-basis-60 w-full hidden md:block">
            <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
              Join the movement of{" "}
              <span className="text-yellowish">
                26K savvy StudyCrowd.AI members
              </span>{" "}
              already empowered - and you're next
            </p>

            <p className=" text-body-small pr-2 text-left pt-6  md:text-h5 xl:text-36 ">
              Discover the secret to success as you become part of our growing
              community.
            </p>
            <p className="text-body-small pr-2 text-left pt-6  md:text-h5 xl:text-36  ">
              Find simple and concise study resources created by top graduates
              who took the same courses as you at US and UK universities
            </p>
            <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
              {/* <div className=" flex-1 flex flex-col justify-center items-center text-center">
                                    <div className="h-120">
                                        <div className="w-116 h-100">
                                            <img src={img1} className="m-auto mt-3 w-full h-full" />
                                        </div>
                                    </div>

                                    <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                                    <p className="h-51">
                                        Study Modules
                                        <br />
                                        All main areas of English law
                                    </p>
                                </div> */}
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img2} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">75K</p>
                <p>
                  Active Users
                  <br />
                  in 2024
                </p>
              </div>
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img3} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">1600+</p>
                <p className="h-51">
                  Tutoring Hours <br />
                  Delivered
                </p>
              </div>
            </div>
          </div>
          <div className="flex-basis-40">
            <Formik
              initialValues={{
                password1: "",
                first_name: "",
                username: "",
                email: "",
                password2: "",
              }}
              validationSchema={validateSchemaSignup}
              onSubmit={(values) => onSignUp(values)}
            >
              {({ isValid, handleSubmit, values, setFieldValue }) => (
                <Form>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="flex flex-col text-darkest items-center">
                      <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                        Sign up to{" "}
                        <span className="text-yellowish">StudyCrowd.AI</span>
                      </p>
                      <div className="w-full mb-3 md:mb-9 ">
                        <GoogleAuthentication />
                        <p className="mb-8 mt-16">Email</p>
                        <Field
                          name="email"
                          as={Input}
                          placeholder="Enter your email"
                          type="text"
                          onChange={(e) => {
                            const email = e.target.value;
                            const username = email.split("@")[0]; // Take the part before @ as the username
                            setFieldValue("email", email);
                            setFieldValue("username", username);
                          }}
                        />
                      </div>

                      <div className="w-full mb-3 md:mb-9 ">
                        <p className="mb-8">First Name</p>
                        <Field
                          name="first_name"
                          as={Input}
                          placeholder="Enter your First Name"
                          type="text"
                        />
                      </div>

                      <div className="w-full ">
                        <p className="mb-8">Password</p>
                        <Field
                          name="password1"
                          as={Input}
                          placeholder="Enter your password"
                          type={showPassword ? "text" : "password"}
                          setShowPassword={setShowPassword}
                          showPassword={showPassword}
                          inputType="password"
                        />
                        <p className="text-small text-darkGrey2 my-8">
                          6+ characters, 1 capital letter{" "}
                        </p>
                      </div>
                      <div className="w-full mb-10 md:mb-14">
                        <p className="mb-8">Confirm password</p>
                        <Field
                          name="password2"
                          as={Input}
                          placeholder="Enter your confirm password"
                          type={showConfirmPassword ? "text" : "password"}
                          setShowPassword={setShowConfirmPassword}
                          showPassword={showConfirmPassword}
                          inputType="password"
                        />
                      </div>
                      <div
                        className="w-full mb-10 md:mb-14"
                        style={{ display: "flex" }}
                      >
                        <label className="condContainer">
                          {" "}
                          I agree to{" "}
                          <a
                            style={{ color: "#cc6828", cursor: "pointer" }}
                            onClick={() => {
                              history.push("/terms-conditions");
                            }}
                          >
                            Terms and Conditions
                          </a>
                          ,{" "}
                          <a
                            style={{ color: "#cc6828", cursor: "pointer" }}
                            onClick={() => {
                              history.push("/privacy-policy");
                            }}
                          >
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a
                            style={{ color: "#cc6828", cursor: "pointer" }}
                            onClick={() => {
                              history.push("/cookies-policy");
                            }}
                          >
                            Cookies Policy
                          </a>{" "}
                          of Simple Studying{" "}
                          <input
                            id="condBox"
                            onClick={(e) => checked(e.target.checked)}
                            type="checkbox"
                            className="default:ring-2 text-primary mr-3 checkBoxOne"
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <div className="pt-4 flex justify-center mb-10">
                      <div className="w-full h-51">
                        <Button
                          onClick={handleSubmit}
                          text="Sign up for free"
                          filled
                          className="buttonClass"
                          disabled={
                            values.first_name === "" ||
                            values.password1 === "" ||
                            values.password2 === "" ||
                            values.email === "" ||
                            !isValid ||
                            values.checked === "false"
                          }
                          loading={state.loader}
                        />
                      </div>
                    </div>
                  </form>
                </Form>
              )}
            </Formik>
            <p className="font-normal text-darkest font-body-large text-center">
              Already have an account?{" "}
              <a
                className="font-bold text-primary cursor-pointer"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    loginModal: true,
                    signUpModal: false,
                  }));
                }}
              >
                {"  "}Log in
              </a>
            </p>
          </div>
        </div>
      </Modal>

      {/* Login Modal */}

      <Modal
        open={state.loginModal}
        onClose={() => setState((prev) => ({ ...prev, loginModal: false }))}
        focusTrapped={false}
        center
        classNames={{
          overlay: "custom-overlay-class",
          modal: "react-modal-class1",
        }}
      >
        <div className="flex gap-6">
          <div className="  flex-basis-60 w-full hidden md:block">
            <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
              Join the movement of{" "}
              <span className="text-yellowish">
                26K savvy StudyCrowd.AI members
              </span>{" "}
              already empowered - and you're next
            </p>

            <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
              Discover the secret to success as you become part of our growing
              community.
            </p>
            <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
              Find simple and concise study resources created by top graduates
              who took the same courses as you at US and UK universities
            </p>
            <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
              {/* <div className=" flex-1 flex flex-col justify-center items-center text-center">
                                    <div className="h-120">
                                        <div className="w-116 h-100">
                                            <img src={img1} className="m-auto mt-3 w-full h-full" />
                                        </div>
                                    </div>

                                    <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                                    <p className="h-51">
                                        Study Modules
                                        <br />
                                        All main areas of English law
                                    </p>
                                </div> */}
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img2} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">75K</p>
                <p>
                  Active Users
                  <br />
                  in 2024
                </p>
              </div>
              <div className="flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                  <div className="w-100 h-100">
                    <img src={img3} className="m-auto mt-3 w-full h-full" />
                  </div>
                </div>

                <p className="fw-bold text-42  text-yellowish mt-4">1600+</p>
                <p className="h-51">
                  Tutoring Hours <br />
                  Delivered
                </p>
              </div>
            </div>
          </div>
          <div className="flex-basis-40">
            <Formik
              initialValues={{
                password: "",
                email: "",
              }}
              validationSchema={validateSchema}
              onSubmit={(values) => handleUserLogin(values)}
            >
              {({ isValid, handleSubmit, values }) => (
                <Form>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="loginInputMain flex flex-col text-darkest items-center">
                      <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                        Sign in to{" "}
                        <span className="text-yellowish">StudyCrowd.AI</span>
                      </p>
                      <div className="w-full mb-3 custom2::mb-9 ">
                        <GoogleAuthentication />
                        <p className="mb-8 mt-16">Email</p>
                        <Field
                          name="email"
                          as={Input}
                          autoComplete="off"
                          placeholder="Enter your email"
                          type="text"
                        />
                      </div>
                      <div className="w-full  mb-3 custom2:mb-9">
                        <p className="mb-8 ">Password</p>
                        <Field
                          name="password"
                          as={Input}
                          placeholder="Enter your password"
                          type={showPassword ? "text" : "password"}
                          setShowPassword={setShowPassword}
                          showPassword={showPassword}
                          inputType="password"
                          autoComplete="off"
                        />
                      </div>
                      <Link
                        className="mb-8 forgetText "
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            loginModal: false,
                          }));
                          history.push("/forget-password");
                        }}
                      >
                        Forget Password?
                      </Link>
                    </div>
                    <div className="pt-4 flex justify-center mb-10">
                      <div className="w-full h:34 md:h-42 lg:h-51">
                        <Button
                          onClick={handleSubmit}
                          text="Log in"
                          filled
                          className="buttonClass"
                          disabled={
                            values.email === "" ||
                            values.password === "" ||
                            !isValid
                          }
                          loading={state.loader}
                        />
                      </div>
                    </div>
                  </form>
                </Form>
              )}
            </Formik>
            <p className="loginBottomText font-normal text-darkest font-body-large text-center">
              You don't have an account yet?{" "}
              <a
                className="font-bold text-primary cursor-pointer"
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    loginModal: false,
                    signUpModal: true,
                  }));
                }}
              >
                {"  "}Sign up
              </a>
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  userSignUp: (body, history) => dispatch(userSignUp(body, history)),
  userLogin: (body, history) => dispatch(userLogin(body, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticeUs);
