import React, {useContext, useEffect, useState} from 'react'
import {connect, useDispatch} from 'react-redux'
import Button from '../../components/EmptyButton'
import SignupImage3 from "../../assets/images/signupImage3.webp";
import Stepper from "../../components/SignUpStepper"
import Select from "../../components/Select";
import ModalSignup from '../../components/SignUpModal/StepTwoModal'
import {useHistory} from "react-router-dom";
import {GetUniversities} from "../../services";
import {createErrorAlert, updateUser} from "../../redux/actions";
import { apiRequest, getUser } from '../../helpers';
import Input from "../../components/Input/InputForm";
import Context from '../../services/Context';
import { Modal } from "react-responsive-modal";
import { width } from 'tailwindcss/defaultTheme';
import convertTextToSlug from '../../helpers/textToSlug';

const SignUpStepTwo = (props) => {
    const {updateUser} = props;
    const history = useHistory()
    const states = props.location.state;
    const user = JSON.parse(getUser())
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedUniversity, setSelectedUniversity] = useState('');
    const [universitiesList, setUniversitiesList] = useState([])
    const [addUniModal, setAddUniModal] = useState(false)
    const [collegeName, setCollegeName] = useState('')
    const dispatch = useDispatch()

    const context = useContext(Context)

    const contentRegion = context?.value?.contentRegion

    useEffect(() => {
        getUniversities()
    }, [])
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflowY = "hidden"
            document.body.style.position = "fixed"
        } else {
            document.body.style.overflowY = "unset"
            document.body.style.position = "unset"
        }
    }, [isModalOpen])

    const getUniversities = async () => {
        await apiRequest({
          method: "get",
          url: `studies/universities/?region=${contentRegion === 0 ? 'UK' : 'US'}`,
        }).then((res) => {
          let values = [];
          res.data.map((item) => {
            values.push({
              value: item,
              label: item.name,
            });
          });
          setUniversitiesList(values)
        });
      };

    const handleSelected = (selectedOption) => {
        setSelectedUniversity(selectedOption);
        let obj = {
            universities_ids: [selectedOption.value.id],
            email: user.email,
            username: user?.username,
            login_from: 'signup'
        }
        updateUser(obj)
    }

    const addCollege = async () => {
        await apiRequest({
            method: 'post',
            url: 'studies/universities/',
            data: {
                region: user?.user_currency,
                name: collegeName,
                slug: convertTextToSlug(collegeName)
            }
        }).then((res) => {
            let obj = {
                value: res.data,
                label: res.data.name
            }
            universitiesList.push(obj)
            setSelectedUniversity(obj);
            setCollegeName('')
            setAddUniModal(false)
            let objUser = {
                universities_ids: [obj.value.id],
                email: user.email,
                username: user?.username
              }
            updateUser(objUser)
            dispatch(createErrorAlert(200, '', 'University/College has been added successfuly', 200))
            

        }).catch((err) => {
            setAddUniModal(false)
            setCollegeName('')
            dispatch(createErrorAlert(400, '', 'Unable to create University/College', 400))
        })
    }

    return (
        <div className='bg-white bg-main-page-back w-full bg-no-repeat bg-cover'>
            <Stepper step={2} exit={() => history.push('/')} totalSteps={3}/>
            <ModalSignup
                isOpen={isModalOpen}
                buttonOneText='Notify us'
                onClickFirstButton={() => setIsModalOpen(false)}
                onClickSecondButton={() => setIsModalOpen(false)}
                closeModal={() => setIsModalOpen(false)}
                title='Where are you studying?'/>
            <div className="px-4 md:px-36 custom2:px-0">

                <p className="mt-10 md:mt-28 custom2:mt-36 mb-14 font-bold text-h4 md:text-large-medium custom2:text-36 text-center text-darkest">Which college or university are you studying at?</p>
                <p className='text-center text-darkest mb-4'> You cannot find your college or university? <a className='text-primary underline cursor-pointer' onClick={() => {
                    setAddUniModal(true)
                }}>Add it here!</a></p>
                <div className="pb-11 flex flex-col justify-center items-center">
                    <div className="w-full md:w-472">
                        <Select
                            placeholder="Add university"
                            values={universitiesList}
                            handleSelected={handleSelected}
                        />
                    </div>
                    {/* <p className="font-normal text-body-large text-center">You cannot find your university?  */}
                    {/* <a
                        className="text-primary underline cursor-pointer" onClick={() => setIsModalOpen(true)}>Notify
                        us</a> */}
                    {/* </p> */}
                    <div className='w-full pt-4 flex justify-center mb-20 mt-12'>
                        <div className='w-full md:px-0 md:w-40 h-51'>
                            <Button onClick={() => history.push({
                                pathname: "/signup/step-three",
                                state: {
                                    selectedUniversity, states
                                }
                            })} disabled={!selectedUniversity} text='Continue' filled/>
                        </div>
                    </div>
                    <img className="hidden md:block" src={SignupImage3} alt="image"/>
                </div>

            </div>
            <Modal
                open={addUniModal}
                onClose={() =>
                    setAddUniModal(false)
                }
                focusTrapped={false}
                center
                classNames={{
                    overlay: "custom-overlay-class",
                    modal: "react-modal-class2", // Use your own custom class here
                }}
                >
                    <p className="font-bold text-center text-h5 mb-10">
                        Which college or university are you studying at?
                    </p>
                    <Input
                        placeholder={'Enter College/University Name'}
                        value={collegeName}
                        setValue={(value) => {
                            setCollegeName(value)
                        }}
                    ></Input>
                    <div className="flex flex-col justify-center items-center w-full mt-4 md:mt-12">
                        <div className="w-56 mt-5 md:mt-0">
                        <Button
                            filled
                        >
                            <p className="text-center text-14 xl:text-body-large" onClick={() => {
                                addCollege()
                            }}>Add College/University</p>
                        </Button>
                        </div>
                    </div>
                
            </Modal>
        </div>

    )
}
const mapDispatchToProps = dispatch => ({
    updateUser: body => dispatch(updateUser(body))
})
export default connect(null, mapDispatchToProps)(SignUpStepTwo)
